import React from 'react';
import {formatDate, stripTime} from '../../../util/format.js';
import {formatDollarAmount} from "../../../util/format";


function LoanSummary(props) {

  const {application, disbursementToStudent} = props;
  const {loanBreakdown} = application;

  const loanAmount = application.enrollmentVerified ?
    loanBreakdown.appliedToTuition : loanBreakdown.requestedLoanAmount;

  return (
    <React.Fragment>
      <div data-testid="loan-summary-loan-amount" className="application-summary__field--strong">
        {formatDollarAmount(loanAmount)}
      </div>

      <div data-testid="loan-summary-disbursement-to-student">
        {(disbursementToStudent && application.enrollmentVerified) ?
          formatDollarAmount(loanBreakdown.disbursementToStudent) : ''}
      </div>
      <div data-testid="loan-summary-app-date">{application.appDate && formatDate(stripTime(application.appDate))}</div>
      <div data-testid="reference-id">#{application.referenceId}</div>
    </React.Fragment>
  );
}

export default LoanSummary;
