import React from 'react';
import './css/ApplicationStatus.css';
import {formatDate} from "util/format";
import StatusBar from "./StatusBar/StatusBar";

const getDisplayForRefundStatus = (application, refundEventTypeDescription) => {
  let {refundEventType, refundStatus, isGraduated, gradDate} = application;
  switch (refundStatus) {
    case 'awaiting_school_confirmation' :
      return {
        barLabelText : refundEventTypeDescription + " Submitted",
        actionOwner : 'Meritize Action Needed',
        actionItem : ( refundEventType === 'refund' ) ? 'We have received your refund request.':
                                        'We have received your refund/withdrawal request.',
      };
    case 'awaiting_school_funds':
    case 'awaiting_schools_funds_risk_share':
      return {
        barLabelText : refundEventTypeDescription + " Processing",
        actionOwner : 'School Action Needed',
        actionItem : 'Please send the funds back to Meritize.',
      };
    case 'awaiting_meritize_risk_share':
      return {
        barLabelText : refundEventTypeDescription + " Processing",
        actionOwner : 'Meritize Action Needed',
        actionItem : 'Meritize is sending the funds to the servicer.',
      };
    default:
      return {
        barLabelText : refundEventTypeDescription + " Completed",
        actionOwner : 'No Action Needed',
        actionItem : isGraduated
          ? 'Your refund request has been processed.'
          : (refundEventType === 'refund'
            ? `Your student will graduate on ${formatDate(gradDate)}!`
            : 'Refund processed! Your student is withdrawn.'
          ),
      };
  }
}
const refundDisplayStatuses = (application) => {

  let {refundEventType, refundStatus, isGraduated } = application;

  if (refundEventType === 'refund') {
       return getDisplayForRefundStatus(application,'Refund' );

  }  else if (refundEventType === 'refund_and_withdrawal') {
    if (isGraduated) {
      return {
        barLabelText: 'Graduated',
        actionOwner: 'No Action Needed',
        actionItem: 'Congrats on your student’s graduation!',
      };
    }

    return getDisplayForRefundStatus(application, 'Refund / Withdrawal');

  }  else { // withdrawal or without a refundEventType
    if (isGraduated) {
      return {
        barLabelText: 'Graduated',
        actionOwner: 'No Action Needed',
        actionItem: 'Congrats on your student’s graduation!',
      };
    }
    if (refundStatus === 'awaiting_meritize') {
      return {
        barLabelText: 'Withdrawal Submitted',
        actionOwner: 'Meritize Action Needed',
        actionItem: 'We are processing your withdrawal request.',
      }
    } else if (refundStatus === 'done') {
      return {
        barLabelText: 'Withdrawal Complete',
        actionOwner: 'No Action Needed',
        actionItem: 'Your student has been withdrawn.',
      }
    }
  }
};

const displayStatuses = (application) => {
  let barStatus, barLabelText, actionOwner, actionItem;

  switch (application.statusCode) {
    case 'application_received':
    case 'waiting_for_school':
    case 'school_certified':
    case 'documentation_required':
    case 'awaiting_post_training_evaluation':
    case 'final_interview':
    case 'background_check':
      barStatus = 'application_received';
      barLabelText = 'Application Received';
      actionOwner = 'Applicant Action Needed';
      if(application.meritDocsNeeded && application.verificationDocsNeeded){
        actionItem = "Merit and additional verification docs needed from applicant.";
      }else if(application.verificationDocsNeeded){
        actionItem = "Additional verification documents needed from applicant.";
      }else {
        actionItem = "Merit documents needed.";
      }
      break;

    case 'application_completed':
    case 'documentation_complete':
    case 'initial_approval':
    case 'conditionally_approved':
    case 'offer_created':
    case 'needs_updated_offer':
    case 'qa_offer_ready':
      barStatus = 'application_completed';
      barLabelText = 'Application Completed';
      actionOwner = 'Meritize Action Needed';
      actionItem = 'Application currently in review process.';
      break;

    case 'cosigner_required':
      barStatus = 'coapplicant_required';
      barLabelText = 'Co-Applicant Needed';
      actionOwner = 'Applicant Action Needed';
      actionItem = 'Qualified co-applicant required to reapply.';
      break;

    case 'final_approval':
    case 'offer_accepted':
    case 'qa_contract_ready':
      barStatus = 'offer_accepted';
      barLabelText = 'Offer Accepted';
      actionOwner = application.isEvNeeded ?
        'School Action Needed' : 'Meritize Action Needed';
      actionItem = application.isEvNeeded ?
        'Enrollment Verification needs to be completed.' :
        'Contract in process of creation.';
      break;

    case 'prom_note_sent':
    case 'prom_note_created':
    case 'prom_note_outstanding':
      barStatus = 'contract_sent';
      barLabelText = 'Contract Sent';
      actionOwner = 'Applicant Action Needed';
      actionItem = `Contract sent to ${application.email}.`;
      break;

    case 'prom_note_signed':
    case 'approved_for_disbursement':
    case 'partially_disbursed':
    case 'fully_disbursed':
    case 'boarded':
    case 'final_disclosure_sent':
      if (application.isGraduated && application.disbursements[0].disbursed) {

        return {...refundDisplayStatuses(application),
               barStatus: 'graduated' };

      } else if (!application.isGraduated && application.disbursements[0].disbursed &&
        !!application.refundEventType) {

        return {...refundDisplayStatuses(application),
          barStatus: application.isInSchool ? 'in_school' : 'funding_secured' };

      } else if (application.isInSchool && application.disbursements[0].disbursed) {

        barStatus = 'in_school';
        barLabelText = 'In school';
        actionItem = `Your student will graduate on ${formatDate(
          application.gradDate)}.`;
        actionOwner = 'No Action Needed';

      } else {
        barStatus = 'funding_secured';
        barLabelText = 'Funding Secured';
        actionOwner = 'No Action Needed';
        if (application.disbursements[0].disbursed &&
          !application.isInSchool) {
          actionItem = 'Your student is disbursed and ready to start class.';
        } else {
          actionItem = `Congrats! We'll disburse your student’s funds on ${formatDate(
            application.disbursements[0].date)}.`;
        }
      }
      break;
    case 'declined':
      if (application.declinedCanReapplyWithCoapplicant) {
        barStatus = 'coapplicant_required';
        barLabelText = 'Co-Applicant Needed';
        actionOwner = 'Applicant Action Needed';
        actionItem = 'Qualified co-applicant required to reapply.';
        break;
      }

      barStatus = 'declined';
      barLabelText = 'Declined';
      actionOwner = 'No Action Needed';
      actionItem = 'Funding request denied for this application.';
      break;

    case 'borrower_withdrawal':
    case 'offer_or_prom_note_timed_out':
    case 'ineligible':
      barStatus = 'application_withdrawn';
      barLabelText = 'Application Withdrawn';
      actionOwner = 'No Action Needed';
      actionItem = 'Applicant withdrew their funding request.';
      break;

    case 'offer_outstanding':
      barStatus = 'offer_sent';
      barLabelText = 'Offer Sent';
      actionOwner = 'Applicant Action Needed ';
      actionItem = `Offer sent to ${application.email}.`;
      break;
    case 'expired':
      barStatus = 'expired';
      barLabelText = 'Expired'
      actionOwner = 'Applicant Action Needed';
      actionItem = 'The applicant must submit a new application to continue.';
      break;
    default:
  }

  return {barStatus, barLabelText, actionOwner, actionItem};
};

export default function ApplicationStatus(props) {
  let {application} = props;

  let barStatus, barLabelText, actionOwner, actionItem;
  try {
    ({barStatus, barLabelText, actionOwner, actionItem} = displayStatuses(
      application));
  } catch (e) {
    barLabelText = '(unable to determine status)';
    console.log(e);
  }

  return (
    <React.Fragment>
      <StatusBar status={barStatus} labelText={barLabelText}
                 refundEventType={application.refundEventType}
                 refundStatus={application.refundStatus}/>
      <div
        className="application-status__action-owner application-summary__field--strong">{actionOwner}</div>
      <div className="application-status__action-item">{actionItem}</div>
    </React.Fragment>
  );
}
