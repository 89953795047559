import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React from "react";
import {withStyles} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import "./css/IconTooltip.scss";

const iconTooltip = (props) => {
  const {iconProps} = props;
  let tooltipProps = {...props};
  delete tooltipProps.iconProps;

  return <Tooltip {...tooltipProps} classes={{tooltip: 'icon-tooltip__tooltip'}}>
    <InfoOutlinedIcon className="icon-tooltip__icon" {...iconProps}/>
  </Tooltip>
}

export const IconTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0 2px 5px -1px rgba(73, 72, 72, 0.5)',
    borderRadius: '4px',
    fontSize: 11,
  },
}))(iconTooltip);
