import React from 'react';
import Card from "@material-ui/core/Card";
import {formatDollarAmount} from "../../util/format";
import './css/LoanBreakdown.css';

function LoanBreakdown(props) {
  let {loanBreakdownData, isEvNeeded, hasDisbursementToStudent} = props;

  if (!loanBreakdownData) {
    loanBreakdownData = {
      financialAid: 0,
      confirmedLoanAmount: 0,
      appliedToTuition: 0,
      disbursementToStudent: 0,
      originationFee: 0,
      requestedLoanAmount: 0,
      totalLoanAmount: 0
    }
  }

  return (
    <Card variant="outlined" className="card no-padding margin_top_small loan-details__card">
      <div className='card-title card-title--loan-details'>Loan Breakdown</div>
      <table className='loan-details-table'>
        <tbody>
        <tr>
          <td className="loan-details-table__financial-aid-title">Financial Aid</td>
          <td className="loan-details-table__financial-aid-amount emphasized"
              data-testid="loan-breakdown-financial-aid">
            {formatDollarAmount(loanBreakdownData.financialAid)}
          </td>
        </tr>
        <tr className='loan-details-table__requested-loan-amount-row'>
          <td className="loan-details-table__requested-loan-title">
            {isEvNeeded ? 'Requested Loan Amount' : 'Confirmed Loan Amount'}
          </td>
          <td className="loan-details-table__requested-loan-amount emphasized"
              data-testid="loan-breakdown-requested-loan">
            {
              isEvNeeded ?
                formatDollarAmount(loanBreakdownData.requestedLoanAmount) :
                formatDollarAmount(loanBreakdownData.confirmedLoanAmount)
            }
          </td>
        </tr>

        <tr>
          <td className="loan-details-table__credit-student-title">Applied to Tuition</td>
          <td className="loan-details-table__credit-student-amount emphasized"
              data-testid="loan-breakdown-applied-to-tuition">
            {formatDollarAmount(loanBreakdownData.appliedToTuition)}
          </td>
        </tr>
        {hasDisbursementToStudent ?
          <tr>
            <td className="loan-details-table__cost-living-title">Disbursed to Student</td>
            <td className="loan-details-table__cost-living-amount emphasized"
                data-testid="loan-breakdown-disbursement-to-student">
              {formatDollarAmount(loanBreakdownData.disbursementToStudent)}
            </td>
          </tr> : ""}
        <tr>
          <td className="loan-details-table__origination-fee-title">Origination Fee</td>
          <td className="loan-details-table__origination-fee-amount emphasized">
            {formatDollarAmount(loanBreakdownData.originationFee)}
          </td>
        </tr>
        <tr className='loan-details-table__bottom-row--greyed'>
          <td className="loan-details-table__loan-amount-title">Total Loan Amount</td>
          <td className="loan-details-table__loan-amount-amount emphasized" data-testid="loan-breakdown-total">
            {formatDollarAmount(loanBreakdownData.totalLoanAmount)}
          </td>
        </tr>
        </tbody>
      </table>
    </Card>
  );
}

export default LoanBreakdown;
