import {IconTooltip} from "component/IconTooltip/IconTooltip";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import React, {memo} from "react";
import {formatPreciseDollarAmount} from "util/format";


const RefundType = memo((props) => {
  const {errorCodes, formVals, changeRefundType, changePartialAmount, fullRefundAmount} = props;

  return (<>
    <hr className="refund-form__hrule"/>
    <div className="refund-form__section-header">
      <h4 className="refund-form__section-title required">What type of refund
        does your student need?</h4>
      {errorCodes.has('amount-to-refund-invalid') &&
      <div className="refund-error">Please enter a dollar amount in the text field</div>}
      {errorCodes.has('amount-to-refund-too-big') &&
      <div className="refund-error">This is greater than the max available for a refund</div>}
      {errorCodes.has('amount-to-refund-too-small') &&
      <div className="refund-error">Please enter a positive number</div>}
    </div>

    <div className="refund-type-container">
      <div className="refund-type">
        <input name="refund-type" type="radio"
               className="refund-type__button" id="radio-full-refund"
               value='full'
               checked={formVals.refundType === 'full'}
               onChange={(e) => changeRefundType(e.currentTarget.value)}
        />
        <div className="refund-type__desc">
          <label htmlFor="radio-full-refund" className="refund-type__label">Full
            Refund</label>
          <label htmlFor="radio-full-refund" data-testid="full-refund-sub-label" className="refund-type__sub-label">
            Your student is due a <br/> {formatPreciseDollarAmount(fullRefundAmount)} refund</label>
        </div>
      </div>
      <div className="refund-type">
        <input name="refund-type" type="radio"
               className="refund-type__button" id="radio-partial-refund"
               value='partial'
               checked={formVals.refundType === 'partial'}
               onChange={(e) => changeRefundType(e.currentTarget.value)}
        />
      <div className="refund-type__desc">
        <label htmlFor="radio-partial-refund" className="refund-type__label">
          Partial Refund
          <IconTooltip
            data-testid="tooltip-partial-refund"
            placement="top-start"
            title="This is the portion of the disbursed amount that was used by the student."
          />
        </label>

                     <span className="refund-type__sub-label">
                       <label htmlFor="refund-amount-field" className="refund-type__sub-label">Your student is due a</label>
                     <Input
                       className="refund-amount-input"
                       inputProps={{
                         'data-testid': "refund-amount-input",
                         'id': 'refund-amount-field',
                       }}
                       value={formVals.refundType === 'partial' ? formVals.partialAmount : ''}
                       onChange={(e) => changePartialAmount(e.currentTarget.value)}
                       onFocus={(e) => changeRefundType('partial')}
                       startAdornment={<InputAdornment position="start">$</InputAdornment>}
                     />
                       <span> refund.</span>
                     </span>
      </div>
    </div>
    </div>
  </>);
});

export default RefundType;
