import React, {memo, useEffect, useRef, useState} from "react";
import TextField from "@material-ui/core/TextField";
import {formatPersonName} from "util/format";

const useDelayedHover = ({timeout}) => {
  const [hover, setHover] = useState(false);
  const hoverTimeout = useRef();
  const [showingHover, setShowingHover] = useState(false);

  useEffect(() => {
    function cleanup() {
      if (hoverTimeout.current !== null) {
        clearTimeout(hoverTimeout.current);
      }
    }
    cleanup();
    if (hover) {
      hoverTimeout.current = setTimeout(() => setShowingHover(true), timeout);
    } else {
      setShowingHover(false);
    }
    return cleanup;
  }, [hover, timeout]);

  return [showingHover, setHover];
};

const PrimTextField = (props) => {
  const {InputProps, ...propsCopy} = {...props};
  const InputPropsCopy = {...InputProps};

  const [showHover, setHover] = useDelayedHover({timeout: 400});

  InputPropsCopy.onMouseEnter = () => setHover(true);
  InputPropsCopy.onMouseLeave = () => setHover(false);
  InputPropsCopy.classes = {
    ...InputProps.classes,
    input: (InputProps.classes.input || '') + ' prim-text-field__text-input',
  };

  return (
    <div className='prim-text-field'>
      {showHover &&
      <div
        className="prim-text-field__tooltip"
        data-testid="prim-tft"
      >{propsCopy.value}</div>}
      <TextField
        InputProps={InputPropsCopy}
        {...propsCopy}
      />
    </div>
  );
};

const ApplicationInfo = memo(({application}) => {
  const {firstName, middleInitial, lastName, email, appId} = application;

  return (
    <div className="refund-form__application-info">
      <PrimTextField
        disabled
        InputLabelProps={{shrink: true}}
        InputProps={{
          classes: {input: 'inspectlet-sensitive'},
        }}
        className="form-text-field"
        label="Name"
        id="refund_form_applicant_name"
        value={formatPersonName({firstName, middleInitial, lastName})}
      />

      <PrimTextField
        disabled
        InputLabelProps={{shrink: true}}
        InputProps={{
          classes: {input: 'inspectlet-sensitive'},
        }}
        className="form-text-field"
        label="Email"
        id="refund_form_applicant_email"
        value={email}
      />

      <TextField
        disabled
        InputLabelProps={{shrink: true}}
        className="form-text-field"
        label="Application ID"
        id="refund_form_application_id"
        value={appId}
      />
    </div>
  );
});

export default ApplicationInfo;
