import React from 'react';
import {formatDate} from '../../util/format.js';
import './css/ApplicantSummary.css';
import PrimText from "../PrimText/PrimText";


function ApplicantSummary (props) {
  let {application} = props;

  return(
    <React.Fragment>
      <PrimText className="application-summary__field--strong inspectlet-sensitive">{application.lastName}, {application.firstName}{application.middleInitial}</PrimText>
      <PrimText className="inspectlet-sensitive">{application.email}</PrimText>
      <div className="inspectlet-sensitive">{formatDate(application.dob)}</div>
    </React.Fragment>
  );
}
export default ApplicantSummary;
