import {API} from "util/APIClient";

export class MixpanelClient {
  trackAppCardToggled(applicationId, expanded) {
    API.mixpanelTrack(expanded ? "Application Card Expanded" : "Application Card Closed", {
      "Application Id": applicationId,
    });
  }

  trackOnlyShowEVsToggled(showOnlyEvs){
    API.mixpanelTrack(showOnlyEvs ? "Only Show EV's Enabled" : "Only Show EV's Disabled" );
  }

  trackSearchSent(searchType, triggeredByEnterKey) {
    const eventName = triggeredByEnterKey
      ? "Enter Search Sent"
      : "Search Sent";

    API.mixpanelTrack(eventName, {
      "Search Type": searchType,
    });
  }

  trackCampusChanged(campusKey) {
    API.mixpanelTrack("Campus Selected", {
      "Campus Key": campusKey,
    });
  }

  trackAppReportButtonClicked(schoolId) {
    API.mixpanelTrack("Application Report Downloaded", {
      "School Ids": schoolId,
    });
  }

  trackRefundsReportButtonClicked(schoolId) {
    API.mixpanelTrack("Refunds Report Downloaded", {
      "School Ids": schoolId,
    });
  }

  trackDisbReportButtonClicked(schoolId) {
    API.mixpanelTrack("Disbursement Report Downloaded", {
      "School Ids": schoolId,
    });
  }

  trackSumReportButtonClicked(schoolId) {
    API.mixpanelTrack("Summary Report Downloaded", {
      "School Ids": schoolId,
    });
  }

  trackRiskShareReportDownloaded(reportUrl, reportMonth) {
    API.mixpanelTrack("Risk Share Report Downloaded", {
      "Report Url": reportUrl,
      "Report Month": reportMonth,
    });
  }

  trackRefundDrawerOpened(applicationId){
    API.mixpanelTrack("Refund/Withdrawal Drawer Opened", {
      "Application Id": applicationId,
    });
  }

  trackRefundCanceled(applicationId){
    API.mixpanelTrack("Refund/Withdrawal Canceled", {
      "Application Id": applicationId,
    });
  }

  trackRefundSubmitted(applicationId, eventType) {
    API.mixpanelTrack("Refund/Withdrawal Submitted", {
      "Application Id": applicationId,
      "Event Type": eventType,
    });
  }

  trackEvDrawerOpened(applicationId) {
    API.mixpanelTrack("EV Drawer Opened", {
      "Application Id": applicationId
    });
  }

  trackEvCanceled(applicationId) {
    API.mixpanelTrack("EV Canceled", {
      "Application Id": applicationId
    });
  }

  trackEvSubmitted(applicationId) {
    API.mixpanelTrack("EV Submitted", {
      "Application Id": applicationId
    });
  }

  trackEvEmailNotificationLink(applicationId) {
    API.mixpanelTrack("EV-Email URL Search Sent", {
      "Application Id": applicationId
    });
  }
}


export class FakeMixpanelClient {

  trackAppCardToggled(applicationId, expanded) { }

  trackOnlyShowEVsToggled(showOnlyEvs){
  }

  trackSearchSent(searchType, triggeredByEnterKey) {}

  trackCampusChanged(campusKey) {
  }

  trackAppReportButtonClicked(schoolId) {
  }

  trackRefundsReportButtonClicked(schoolId) {
  }

  trackDisbReportButtonClicked(schoolId) {
  }

  trackSumReportButtonClicked(schoolId) {
  }

  trackRiskShareReportDownloaded(reportUrl, reportMonth) {
  }

  trackRefundDrawerOpened(applicationId){
  }

  trackRefundCanceled(applicationId){
  }

  trackRefundSubmitted(applicationId, eventType) {
  }

  trackEvDrawerOpened(applicationId) {
  }

  trackEvCanceled(applicationId) {
  }

  trackEvSubmitted(applicationId) {
  }

  trackEvEmailNotificationLink(applicationId) {
  }
}
