import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import "./css/CampusSelector.css";
import FormLabel from "@material-ui/core/FormLabel";

export default function CampusSelector(props) {
    const {onChange, campusId, disabled} = props;
    let campuses = [...(props.campuses || [])];

    campuses.forEach((campus) => {
        if (!campus.campusId) {
            throw new Error("Invalid campus: " + campus);
        }
    })

    return (
        <>
            <FormLabel htmlFor="campus-selector" className="campus-selector__label">Campus:</FormLabel>
            <FormControl variant="outlined" className="campus-selector__control">
                <Select
                    native
                    disabled={disabled}
                    value={campusId}
                    onChange={(e) => onChange(e.currentTarget.value)}
                    inputProps={
                        {
                            'id': 'campus-selector',
                            'data-testid': 'campus-selector',
                        }
                    }
                >
                    {campuses !== null && campuses.map((campus) =>
                        <option key={campus.campusId} value={campus.campusId} data-testid='campus-option'>{campus.name}</option>
                      )
                    }
                </Select>
            </FormControl>
        </>
    )
}
