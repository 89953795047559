import React, {useCallback, useEffect, useReducer, useRef, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {KeyboardDatePicker} from '@material-ui/pickers';
import formatISO from 'date-fns/formatISO';
import isValid from 'date-fns/isValid';
import {REFUND_ONLY, REFUND_REASONS_MAP, REFUND_WITHDRAWAL, WITHDRAWAL_ONLY} from './Constants';
import {API} from 'util/APIClient';
import UserInfo from 'util/UserInfo';
import {formatPreciseDollarAmount} from '../../util/format';
import {Auth} from 'util/withAuth';
import './css/RefundForm.scss';
import 'css/ctaButton.scss';
import ApplicationInfo from './ApplicationInfo';
import RefundReason from './RefundReason';
import RefundType from './RefundType';
import RefundCalculatorDisplay from './RefundCalculatorDisplay';
import {produce} from 'immer';

const hasWithdrawal = (eventType) => (eventType === REFUND_WITHDRAWAL || eventType === WITHDRAWAL_ONLY);
const hasRefund = (eventType) => (eventType === REFUND_WITHDRAWAL || eventType === REFUND_ONLY);

function partiallyDisbursed(application) {
  return application.disbursements.some(disbursement => !disbursement.disbursed)
}

const userInfo = new UserInfo(Auth);

const knownErrors = new Set([
  'amount-to-refund-invalid',
  'amount-to-refund-too-big',
  'amount-to-refund-too-small',
]);

function difference(setA, setB) {
  let _difference = new Set(setA)
  for (let elem of setB) {
    _difference.delete(elem)
  }
  return _difference
}

const isSubset = (setA, setB) => difference(setA, setB).size === 0;

const RefundForm = (props) => {
  let {onCancel, application, onSuccess, recalculationDelay, schoolId} = props;
  recalculationDelay = recalculationDelay || 500;
  const showLoanDiscount = application.disbursements[0].amount.loanDiscount > 0;

  let [formVals, formUpdate] = useReducer((state, action) => {
    if (action.eventType !== undefined && action.eventType !== state.eventType) {
      action.reason = '';
    }
    return produce(state, (draftState) => {
      for (const prop in action) {
        draftState[prop] = action[prop];
      }
    });
  }, {
    eventType: REFUND_ONLY,
    reason: '',
    withdrawalDate: {date: null, dateStr: ''},
    partialAmount: '',
    refundType: '',
    paymentMethod: '',
    notes: '',
    cancelDisbursements: null
  })
  const setEventType = useCallback((x) => formUpdate({eventType: x}), []);
  const setReason = useCallback((x) => formUpdate({reason: x}), []);
  const setWithdrawalDate = useCallback((x) => formUpdate({withdrawalDate: x}), []);
  const setNotes = useCallback((x) => formUpdate({notes: x}), []);
  const setPartialAmount = useCallback((x) => {
    setCalculatingRefund(true)
    x = x.replace(/[^.0-9]/g, '');
    const match = /[0-9]+(\.[0-9]{0,2})?/.exec(x);
    if (match) {
      x = match[0];
    } else {
      x = '';
    }
    formUpdate({partialAmount: x});
  }, []);
  const setRefundType = useCallback((x) => formUpdate({refundType: x}), []);
  const setPaymentMethod = useCallback((x) => formUpdate({paymentMethod: x}), []);

  let formHeader = useRef(null);
  let [fullRefundAmount, setFullRefundAmount] = useState({});
  let partialAmountTimeout = useRef(null);
  let [partialAmountToCalc, setPartialAmountToCalc] = useState('');
  let [refundValues, updateRefundValues] = useState({});
  let [submitState, setSubmitState] = useState(null);
  let [errorCodes, setErrorCodes] = useState(new Set());
  let [paymentDetails, setPaymentDetails] = useState({
    bankName: null,
    accountName: null,
    routingACH: null,
    routingWire: null,
    accountNumber: null,
    mailingAddress: null
  });
  const [calculatingRefund, setCalculatingRefund] = useState(false);
  let cancelDisbursementsOptionAvailable = !hasWithdrawal(formVals.eventType) && partiallyDisbursed(application);

  const isValidRefund = (formVals.refundType === 'full' || !!formVals.partialAmount) && !!formVals.paymentMethod;



  const submitEnabled =
    !!formVals.reason && //has reason
    (!hasWithdrawal(formVals.eventType) || isValid(formVals.withdrawalDate.date)) && //refund-only or withdrawal-date
    (!hasRefund(formVals.eventType) || isValidRefund) && // full or withdrawal & payment method
    !(cancelDisbursementsOptionAvailable && formVals.cancelDisbursements === null) &&
    !errorCodes.size &&
    !calculatingRefund;

  const handlePaymentMethodChange = useCallback((e) => setPaymentMethod(e.currentTarget.value), [setPaymentMethod]);

  const handleRequestError = (err, {genericErrorCode}) => {
    if (!err.response) {
      setErrorCodes(new Set([genericErrorCode]));
    } else if (err.response.status === 422) {
      const errorCodes = new Set(err.response.data.errors.map(e => e.replace(/:.*/, '')));
      if (!isSubset(errorCodes, knownErrors)) {
        errorCodes.add(genericErrorCode);
      }
      setErrorCodes(errorCodes);
    } else {
      setErrorCodes(new Set([genericErrorCode]));
    }
  };

  useEffect(() => {
    API.getPaymentDetails().then((resp) => {
      setPaymentDetails(resp.data);
    });
  }, []);


  useEffect(() => {
    API.calculateRefund({
      params: {
        applicationId: application.appId
      },
      method: 'GET',
    }).then((resp) => {
      setFullRefundAmount(resp.data.totalAmountDisbursed);
    }).catch(err => {
      setFullRefundAmount(null);
    });
  }, [application.appId]);

  useEffect(() => {
    function cleanup() {
      if (partialAmountTimeout.current !== null) {
        clearTimeout(partialAmountTimeout.current);
      }
    }

    cleanup();
    partialAmountTimeout.current = setTimeout(() => setPartialAmountToCalc(formVals.partialAmount), recalculationDelay);
    return cleanup;
  }, [formVals.partialAmount, recalculationDelay])

  useEffect(() => {
    let active = true;
    if (!formVals.refundType) {
      return;
    }
    let params = {
      applicationId: application.appId
    };
    if (formVals.refundType === 'partial') {
      if (!partialAmountToCalc) {
        updateRefundValues({});
        return;
      }
      params.amountToRefund = partialAmountToCalc;
    }
    setCalculatingRefund(true);
    API.calculateRefund({
      params: params,
      method: 'GET',
    })
      .then(({data}) => {
        if (!active) return;
        setErrorCodes(new Set());
        updateRefundValues(data);
      })
      .catch((err) => {
        if (!active) return;
        updateRefundValues({});
        handleRequestError(err, {genericErrorCode: 'generic-calculate-error'})
      })
      .finally(() => {setCalculatingRefund(false)});
    return () => {
      active = false;
    };
  }, [application.appId, formVals.refundType, partialAmountToCalc]);

  useEffect(() => {
    let active = true;
    if (submitState === 'submitting') {
      setErrorCodes(new Set());
      const {
        eventType,
        reason,
        notes,
        withdrawalDate,
        paymentMethod,
        refundType,
        partialAmount,
        cancelDisbursements
      } = formVals;

      let data = {
        applicationId: application.appId,
        eventType,
        reason,
        notes,
      };
      if (cancelDisbursementsOptionAvailable) {
        data.cancelDisbursements = cancelDisbursements;
      }
      if (hasWithdrawal(eventType)) {
        data.withdrawalDate = withdrawalDate.date ?
          formatISO(withdrawalDate.date, {representation: 'date'}) : null
      }
      if (hasRefund(eventType)) {
        data = {
          ...data,
          paymentMethod,
          amountToRefund: refundType === 'full' ? null : partialAmount.toString().replace(/[ ,]/g, ''),
        }
      }
      userInfo.getMixpanelClient().trackRefundSubmitted(application.appId, eventType);

      API.submitRefund({data})
        .then((resp) => {
          active && setSubmitState('done');
        }).catch(err => {
        if (!active) return;
        setSubmitState(null);
        handleRequestError(err, {genericErrorCode: 'generic-submit-error'})
      });
    } else if (submitState === 'done') {
      onSuccess(application.appId);
    }
    return () => {
      active = false;
    };
  }, [onSuccess, submitState, formVals, application.appId, cancelDisbursementsOptionAvailable])

  useEffect(() => {
    if (errorCodes.has('generic-submit-error') && formHeader.current) {
      formHeader.current.scrollIntoView(false);
    }
  }, [errorCodes])

  const paymentDescriptionWire = (paymentDetails) => (
    <div className="payment-method__details-wrapper">-&nbsp;
      <div className="payment-method__details inspectlet-sensitive">
        Bank Name: {paymentDetails.bankName}<br/>
        Account Name: {paymentDetails.accountName}<br/>
        Routing ACH: {paymentDetails.routingACH}<br/>
        Account Number: {paymentDetails.accountNumber}<br/>
        <br/>
        Routing Wire: {paymentDetails.routingWire}<br/>
        Account Number: {paymentDetails.accountNumber}
      </div>
    </div>);

  const paymentDescriptionMail = (paymentDetails) => (
    <div className="payment-method__details inspectlet-sensitive" dangerouslySetInnerHTML={{__html: paymentDetails.mailingAddress}}/>
  )

  const paymentDescriptionFedex = (paymentDetails) => (
    <div className="payment-method__details inspectlet-sensitive" dangerouslySetInnerHTML={{__html: paymentDetails.fedexAddress}}/>
  )

  return (
    <div className="refund-form__container" data-testid="refund-drawer">
      <div ref={formHeader} className="refund-form__section-header refund-form__section-header--title">
        <h3 className="refund-form__title">Refund/Withdraw Form</h3>
        {errorCodes.has('generic-submit-error') &&
        <div className="refund-error">We were unable to submit your refund at this time.
          Please try again, and if the problem perisists contact your Meritize partnerships associate.</div>}
        {errorCodes.has('generic-calculate-error') &&
        <div className="refund-error">We were unable to calculate your refund amount at this time.
          If the problem perisists, please contact your Meritize partnerships associate.</div>}
      </div>

      <ApplicationInfo application={application}/>

      <div className="refund-event-type-section">
        <h4 className="refund-form__section-title required">Please select from one of the options below</h4>
        <div className="refund-event-type-selector">
          <input className="refund-event-type-selector__button" type="radio"
                 name="eventType" value="refund" id="event-type-refund"
                 checked={formVals.eventType === REFUND_ONLY}
                 onChange={(e) => setEventType(e.currentTarget.value)}
          />
          <label className="refund-event-type-selector__label" htmlFor="event-type-refund">Refund Only</label>
          <input className="refund-event-type-selector__button" type="radio"
                 name="eventType" value="refund_and_withdrawal" id="event-type-refund_and_withdrawal"
                 checked={formVals.eventType === REFUND_WITHDRAWAL}
                 onChange={(e) => setEventType(e.currentTarget.value)}
          />
          <label className="refund-event-type-selector__label" htmlFor="event-type-refund_and_withdrawal">Refund /
            Withdrawal</label>
          <input className="refund-event-type-selector__button" type="radio"
                 name="eventType" value="withdrawal" id="event-type-withdrawal"
                 checked={formVals.eventType === WITHDRAWAL_ONLY}
                 onChange={(e) => setEventType(e.currentTarget.value)}
          />
          <label className="refund-event-type-selector__label refund-event-type-selector__label--end"
                 htmlFor="event-type-withdrawal">Withdrawal Only</label>
        </div>
      </div>

      <RefundReason reason={formVals.reason}
                    optionNames={
                      REFUND_REASONS_MAP[formVals.eventType]
                    }
                    eventType={formVals.eventType}
                    onChange={setReason}/>

      {
        cancelDisbursementsOptionAvailable &&
        <div className="refund-cancel-disbursements">
          <span className="refund-cancel-disbursements__label">Cancel future disbursements?</span>

          <div className="refund-cancel-disbursements-options">
            <input className="refund-cancel-disbursements__button" type="radio"
                   data-testid="cancel-disbursements-yes"
                   name="cancelDisbursements" value="yes" id="cancel-disbursements-yes"
                   checked={formVals.cancelDisbursements !== null && formVals.cancelDisbursements}
                   onChange={(e) => formUpdate({cancelDisbursements: true})}
            />
            <label className="refund-cancel-disbursements-selector__label"
                   htmlFor="cancel-disbursements-yes">Yes</label>

            <input className="refund-cancel-disbursements__button" type="radio"
                   data-testid="cancel-disbursements-no"
                   name="cancelDisbursements" value="no" id="cancel-disbursements-no"
                   checked={formVals.cancelDisbursements !== null && !formVals.cancelDisbursements}
                   onChange={(e) => formUpdate({cancelDisbursements: false})}
            />
            <label className="refund-cancel-disbursements-selector__label" htmlFor="cancel-disbursements-no">No</label>
          </div>
        </div>
      }

      {hasWithdrawal(formVals.eventType) &&
      <div className="refund-withdrawal-date-section">
        <label htmlFor="withdrawal-date">Withdrawal Date</label>
        <KeyboardDatePicker
          classes={{
            root: 'refund-withdrawal-date',
          }}
          autoOk
          required
          format="MM/dd/yyyy"
          InputAdornmentProps={{position: 'end', fontSize: 'small'}}
          InputLabelProps={{shrink: true}}
          InputProps={{
            'id': 'withdrawal-date',
            classes: {
              input: 'refund-withdrawal-date__input'
            },
          }}
          variant="inline"
          value={null}
          inputValue={formVals.withdrawalDate.dateStr}
          onChange={(date, dateStr) => {
            setWithdrawalDate({date, dateStr});
          }}
        />
      </div>
      }


      {hasRefund(formVals.eventType) &&
      <>
        <RefundType
          errorCodes={errorCodes}
          formVals={formVals}
          fullRefundAmount={fullRefundAmount}
          changeRefundType={setRefundType}
          changePartialAmount={setPartialAmount}
          usedPercentage={refundValues.usedPercentage}
        />

        <hr className="refund-form__hrule"/>

        <RefundCalculatorDisplay refundValues={refundValues} showLoanDiscount={showLoanDiscount} schoolId={schoolId}/>

        <hr className="refund-form__hrule"/>

        <h4 className="refund-form__section-title required">How are you sending
          the <span data-testid="refund-calc-amount-to-send">{
            refundValues.amountToSend ? formatPreciseDollarAmount(refundValues.amountToSend) : <>$<u>&emsp;&emsp;&emsp;&emsp;</u></>
          }</span> to Meritize?</h4>
        <span style={{ marginTop: 0, marginBottom: '0.75rem' }} className="payment-method__mail-disclaimer">Please include your school name as it appears in the application and the App ID(s) that is/are covered by the refund in the memo/notes section of your payment.</span>
        {[
          {code: 'ach/wire', label: 'ACH/Wire', id: 'radio-payment-wire', description: paymentDescriptionWire},
          {code: 'mail', label: 'Mail', id: 'radio-payment-mail', description: paymentDescriptionMail},
          {code: 'fedex', label: 'Fedex', id: 'radio-payment-fedex', description: paymentDescriptionFedex},
        ].map(m => (
          <div key={m.code} className="payment-method">
            <input name="payment_method" type="radio"
                   className="payment-method__button"
                   id={m.id}
                   value={m.code}
                   checked={formVals.paymentMethod === m.code}
                   onChange={handlePaymentMethodChange}
            />
            <div className="refund-type__desc">
              <label className="payment-method__label" htmlFor={m.id}>{m.label}</label>
              {formVals.paymentMethod === m.code && m.description(paymentDetails)}

            </div>
            {
              formVals.paymentMethod === m.code &&
              m.code === 'mail' &&
              <span className="payment-method__mail-disclaimer">This option takes the longest for your payment to process.</span>
            }
          </div>
        ))}
      </>
      }
      <hr className="refund-form__hrule"/>

      <h4 className="refund-form__section-title">Additional Info</h4>
      <TextField
        className="refund-form__notes"
        id="outlined-multiline-static"
        placeholder="Notes for the Meritize Team"
        multiline
        inputProps={{
          maxLength: 5000,
          className: 'refund-form__notes-input',
        }}
        InputProps={{
          className: 'refund-form__notes-input-container',
        }}
        rows={4}
        variant="outlined"
        onChange={(e) => setNotes(e.currentTarget.value)}
      />

      <h4 className="refund-form__section-title">This form was completed by</h4>

      <div className="refund-form__user-info">
        <TextField
          disabled
          InputLabelProps={{shrink: true}}
          InputProps={{
            classes: {input: 'inspectlet-sensitive'},
          }}
          className="form-text-field form-text-field--prominent form-text-field--refund-user-email"
          label="Email"
          value={userInfo.getSubject()}
          inputProps={{'data-testid': 'refund-user-email'}}
        />
      </div>

      {hasRefund(formVals.eventType) &&
      <div className="refund-form__disclaimer" data-testid="refund_form_disclosure_div">
        Final refund numbers are subject to verification by Meritize.
        <br/>
      </div>
      }

      <div className="refund-form__action-buttons">
        <button className="refund-form__cancel-button"
                onClick={onCancel}>Cancel
        </button>
        <button className="refund-form__submit-button cta-button cta-button--confirm"
                {...(submitEnabled ? {} : {disabled: true})}
                onClick={() => setSubmitState('submitting')}>Submit
        </button>

      </div>
    </div>
  );
};

export default RefundForm;
