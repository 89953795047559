import React from "react";
import "../../css/ctaButton.scss"
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

const CTAButton = (props) => {
  let {btnStyle, children, classAffix, 'data-testid': dataTestId, disabled} = props;
  switch (btnStyle) {
    case 'confirm': break;
    case 'action': break;
    case 'submit': break;
    default:
      btnStyle = 'action';
  }

  return (
      <Button
        classes={{label: classAffix}}
        className={"cta-button cta-button--" + btnStyle + ' ' + classAffix}
        onClick={props.onClick}
        data-testid={dataTestId}
        disabled={disabled}
      >
        {children}
      </Button>
  );
};

CTAButton.propTypes = {
  children: PropTypes.any,
  btnStyle: PropTypes.string,
  classAffix: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CTAButton;
