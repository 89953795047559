import React, {PureComponent} from 'react';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import "./css/SchoolSelector.css";
import FormLabel from "@material-ui/core/FormLabel";
import EllipsisBox from "../../assets/images/ellipsisbox.svg";
import EllipsisBoxFocused from "../../assets/images/ellipsisbox-focused.svg";
import Icon from "@material-ui/core/Icon";



export default class SchoolSelector extends PureComponent {
  render() {
    const {onChange, schoolId, showAllSchoolsOption} = this.props;
    let schools = [...(this.props.schools || [])];

      return (
      <>
        <FormLabel htmlFor="school-selector" className="school-selector__label">School:</FormLabel>
        <FormControl variant="outlined" className="school-selector__control">
          <Select
            native
            value={schoolId}
            IconComponent={props => (

              <Icon {...props} className={`material-icons ${props.className}`} style={{width:'56px' ,  ...props.style}}>
                <img className='select-icon-focused' alt="ellipsisBox" src={EllipsisBoxFocused} />
                <img className='select-icon' alt="ellipsisBox" src={EllipsisBox} />
              </Icon>
            )}
            onChange={(e) => onChange(e.currentTarget.value)}
            inputProps={
              {
                'id': 'school-selector',
                'data-testid': 'school-selector',
              }
            }
          >
            {
              schools !== null && (
                [
                  showAllSchoolsOption ?
                    <option key="all" value="all" data-testid='school-option'>All Schools</option> : null,
                  ...schools.map((school) => <option key={school.schoolId} value={school.schoolId}
                                                     data-testid='school-option'>{school.name}</option>)
                ]
              )
            }
          </Select>
        </FormControl>
      </>
    )
  }
}
