import React from 'react';
import Card from "@material-ui/core/Card";
import {formatDate, formatDollarAmount} from "../../util/format.js";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import "./css/Disbursements.css";

const emptyDisbursementAmount = {
  schoolAdvance: 0,
  riskShare: 0,
  total: 0,
}

function formatDollarAmountOrNull(amt) {
  return amt === null ? 'null' : formatDollarAmount(amt);
}


function Disbursements (props) {
  const {disbursements = [], totalDisbursementAmount = emptyDisbursementAmount} = props;

  const emptyDisbursements = [...new Array(Math.max(3 - disbursements.length, 0))];

  return (
    <Card variant="outlined" className="card margin_top_small disbursements disbursements__card">
      <div className="disbursements card-title card-title--disbursements">Disbursement Breakdown</div>

      <table className="disbursements color-gray" >
        <tbody>
        <tr>
          <th className='empty-boxes no-border'/>
          {disbursements.map((d, i) =>
              <td  key={i} data-disbursement-checkbox={i + 1} className='empty-boxes no-border'>
                {d.disbursed ?
                  <CheckBoxIcon className="disbursed-checkbox"/> :
                  <CheckBoxOutlineBlankIcon className="disbursed-checkbox"/>
                }
              </td>
          )}
          <td className='no-border'>
          </td>
        </tr>
          <tr>
            <th className='no-border-top'>Disbursement Date</th>
            {disbursements.map((d, i) =>
              <td key={i} data-disbursement-number={i + 1}  className='no-border-top emphasized'>
                {formatDate(d.date)}
              </td>
            )}
            {emptyDisbursements.map((_, i) => <td key={i}  className='no-border-top'/>)}
            <td className="no-border-top total-other total-other--strong" >Total</td>
          </tr>
          <tr>
            <th>School Advance</th>
            {disbursements.map((d, i) =>
              <td key={i} className="emphasized" data-testid={`disbursements-school-advance-${i+1}`}>{formatDollarAmountOrNull(d.amount.schoolAdvance)}</td>
            )}
            {emptyDisbursements.map((_, i) => <td key={i}/>)}
            <td className="total-other emphasized">{formatDollarAmount(totalDisbursementAmount.schoolAdvance)}</td>
          </tr>
          <tr>
            <th>Risk Share</th>
            {disbursements.map((d, i) =>
              <td key={i} className="emphasized" data-testid={`disbursements-risk-share-${i+1}`}>{formatDollarAmountOrNull(d.amount.riskShare)}</td>
            )}
            {emptyDisbursements.map((_, i) => <td key={i}/>)}
            <td className="total-other emphasized">{formatDollarAmountOrNull(totalDisbursementAmount.riskShare)}</td>
          </tr>
        {
          totalDisbursementAmount.loanDiscount > 0 &&
          <tr>
            <th>Loan Discount</th>
            {disbursements.map((d, i) =>
              <td key={i} className="emphasized" data-testid={`disbursements-loan-discount-${i+1}`}>{formatDollarAmountOrNull(d.amount.loanDiscount)}</td>
            )}
            {emptyDisbursements.map((_, i) => <td key={i}/>)}
            <td className="total-other emphasized" data-testid='disbursements-loan-discount-total'>{formatDollarAmountOrNull(totalDisbursementAmount.loanDiscount)}</td>
          </tr>
        }
          <tr  className='disbursements__bottom-row'>
            <th>Applied to Tuition</th>
            {disbursements.map((d, i) =>
              <td className="total-other emphasized" key={i} data-testid={`disbursements-total-${i+1}`}>{formatDollarAmountOrNull(d.amount.total)}</td>
            )}
            {emptyDisbursements.map((_, i) => <td key={i}></td>)}
            <td className="total emphasized" data-testid={`disbursements-total-all`}>{formatDollarAmount(totalDisbursementAmount.total)}</td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
}

export default Disbursements;
