import React, {useEffect, useState} from 'react';
import './css/ChangePassword.css';
import Input from "@material-ui/core/Input";
import {FormControl, InputLabel} from "@material-ui/core";
import CTAButton from "../CTAButton/CTAButton";
import {Auth} from 'util/withAuth';
import {API} from 'util/APIClient';
import Header from "../Header/Header";
import Box from "@material-ui/core/Box";
import Environment from "util/Environment";
import LoadingIndicator from 'component/LoadingIndicator/LoadingIndicator';

export default function ChangePassword(props) {

  const errorStrings = {
    'common-password': 'The entered password matches one of the most common passwords on the internet. Since your account will be used to store personal data, we ask that you choose another.',
    'password-contains-meritize': 'The password must not contain "Meritize".',
    'password-too-short': 'The new password must be at least 8 characters.',
    'passwords-mismatch': 'The New password and Confirm password fields do not match.',
    'email-missing': 'Internal Server Error',
    'email-mismatch': 'Internal Server Error',
    'null-password': 'A valid password must be entered to continue.',
  };

  useEffect(() => {
    if (Environment.isProdBuild()) {
      // This is only for testing.  It's tricky to ensure that the Referrer-Policy header is correct.
      var script = document.createElement('script');
      script.src = '/referrer_test';
      document.head.appendChild(script);
    }
  }, []);

  const [errorMessages, setErrorMessages] = useState(null);

  let [newPassword, setNewPassword] = useState(null);
  let [confirmedPassword, setConfirmedPassword] = useState(null);
  let email = new URLSearchParams(props?.location?.search).get("email");
  let jwtToken = new URLSearchParams(props?.location?.search).get("token");
  let [loading, setLoading] = useState(true);

  function parseErrors(errorObjects) {
    let errorMessages = [];

    errorObjects.forEach((errorObject) => {
      errorMessages.push(errorStrings[errorObject.code] || "Bad input, please check form values and try again.");
    })

    return errorMessages;
  }

  useEffect(() => {
    API.resetPasswordVerifyToken({
      headers: {
        'Meritize-Auth-Token': jwtToken,
      }
    }).then(res => {
      setLoading(false);
    }).catch(error => {
      if (error.response?.status === 401) {
        props.history.push('/login', {expiredTokenPasswordChange: true})
      } else {
        setLoading(false);
        setErrorMessages(["Server error, please contact support."]);
      }
    });
  }, [jwtToken, props.history]);

  let submitNewPassword = () => {
    Auth.submit('/reset-password', {
      data: {
        email: email,
        password: newPassword,
        passwordRepeat: confirmedPassword
      },
      headers: {
        'Meritize-Auth-Token': jwtToken,
      }
    }).then(res => {
      props.history.push('/login', {successfulPasswordChange: true})
    }).catch(error => {
      if (error.response?.status === 422) {
        setErrorMessages(parseErrors(error.response.data.errors))
      } else if (error.response?.status === 401) {
        setErrorMessages([<>Your token may be expired. Try <a href="/">resetting your password again.</a></>])
      } else {
        setErrorMessages(["Server error, please contact support."]);
      }
    });
  };

  return (
    <>
      {
        loading ?
          <LoadingIndicator/>
          :
          <div className="change-password--container">
            <Header/>
            <div className="change-password--sub-container">
              <div className="change-password--header">Change Password</div>
              <div className="change-password--text">Enter a new password for {email}. We highly recommend you create a
                unique password - one that you don’t use for any other websites
              </div>
              <div className="change-password--sub-header">Your password must include:</div>
              <div className="change-password--text">At least 8 Characters</div>
              <div>
                <FormControl>
                  <InputLabel htmlFor="new-password">
                    New Password*
                  </InputLabel>
                  <Input
                    id="new-password"
                    type="password"
                    className="change-password--text-field"
                    onChange={(event) => setNewPassword(event.target.value)}
                  />
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <InputLabel htmlFor="confirm-password">
                    Confirm New Password*
                  </InputLabel>
                  <Input
                    className="change-password--text-field"
                    type="password"
                    id="confirm-password"
                    onChange={(event) => {
                      setConfirmedPassword(event.target.value)
                    }}
                  />
                </FormControl>
              </div>

              {(errorMessages) &&
              <div className="ev-form__row" data-testid="error-msg-container">
                <Box
                  border={1}
                  borderColor="error.main"
                  borderRadius="borderRadius"
                  variant="outlined"
                  className="ev-form__error-message-box"
                >
                  {errorMessages.map((obj, index) => {
                    return <div data-testid="error-msg" key={index}>{obj}</div>
                  })}
                </Box>
              </div>
              }

              <div className="change-password--cta-button">
                <CTAButton
                  classAffix="change-password--cta-button__rounded"
                  btnStyle="submit"
                  onClick={submitNewPassword}
                >
                  RESET MY PASSWORD
                </CTAButton>
              </div>
            </div>

          </div>
      }
    </>
  );
}
