import React, {useState} from "react";
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import './css/RiskShareReportMenu.scss';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClockSvg from '../../assets/images/clock.svg';
import UserInfo from '../../util/UserInfo';
import {Auth} from '../../util/withAuth';

const userInfo = new UserInfo(Auth);

export const formatRiskShareReportDate = (date) => {
  return format(parseISO(date), "MMMM yyyy");
};

const RiskShareReportMenu = (props) => {
  let [open, setOpen] = useState(false);

  let menuClicked = () => {
    setOpen(!open);
  }
  let reportButtonClicked = (report) => {
    setOpen(false);
    userInfo.getMixpanelClient().trackRiskShareReportDownloaded(report.url, report.month);
  }

  const menuBlur = (e) => {
    const currentTarget = e.currentTarget;
    // the timeout is needed to download the reports, without that the dropdown closes before the report is downloaded
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setOpen(false);
      }
    }, 0);
  };

  return (
    <div className="risk-share-report-menu" onBlur={menuBlur}>
      <button className="risk-share-report-menu__title" onClick={menuClicked}>
        Download Risk Share Report <ArrowDropDownIcon className={"risk-share-report-menu__title-arrow"}/>
      </button>
      <ul className="risk-share-report-menu__content">
        {open &&
        props.availableReports.map(report =>
          <li
            className={`risk-share-report-menu__item ${report.approved ? '' : 'risk-share-report-menu__item--unapproved'}`}
            key={report.month}>
            <a href={report.url} onClick={() => reportButtonClicked
            (report)}>{formatRiskShareReportDate(report.month)}</a>
          </li>
        )
        }
        {open && props.availableReports.length === 0 &&
        <li className={`risk-share-report-menu__item`}>
          <div className="risk-share-report-menu__empty-list--icon-container">
            <img alt="clock-img" src={ClockSvg}/>
          </div>
          <div className="risk-share-report-menu__empty-list--title">
            Hold Tight!
          </div>
          <div className="risk-share-report-menu__empty-list--msg">
            There is no risk share report available for download at this time.
          </div>
        </li>
        }
      </ul>
    </div>
  );
}

export default RiskShareReportMenu;
