import React, {useEffect, useState} from 'react';
import './css/Header.css';
import DefaultLogo from '../../assets/images/meritize-logo.svg';
import WinterLogo from '../../assets/images/seasonalBanners/meritize-wordmark-winter.svg';
import {API} from "../../util/APIClient";
import {Auth} from 'util/withAuth';
import {getCurrentDate} from "../../util/DateHelper";

const Header = (props) => {

  const [marketingUrl, setMarketingUrl] = useState(undefined);

  const checkIsWinter = () => {
    const dt = getCurrentDate()
    const month = dt.getMonth() + 1;
    const day = dt.getDate()

    return month == 12
      ? day >= 15
      : month == 3
        ? day < 21
        : month < 3;
  }

  const isWinter = checkIsWinter();

  const bannerClass = isWinter ? 'header-winter' : 'header'
  const logoClass = isWinter ? 'desktop-logo-winter' : 'desktop-logo-default'
  const logoAltText = isWinter ? 'Meritize Winter' : 'Meritize'
  const logoImg = isWinter ? WinterLogo : DefaultLogo

  useEffect(() => {
    if (Auth.loggedIn()) {
      API.marketingMaterials()
        .then(res => res.data)
        .then(url => setMarketingUrl(url));
    }
  }, []);

  return (
    <div className={props.secondaryUserName ? `${bannerClass} header__view-as` : bannerClass}>
      <a href={props.homeLink || "/"}>
        <img className={"desktop-logo "+logoClass} alt={logoAltText} src={logoImg}/>
      </a>
      {props.secondaryUserName ?
        <div className="header__secondary-user-name">
          Current user view is: {props.secondaryUserName}
        </div>
        : null
      }
      <div className="header__div--feedback-survey">
        {marketingUrl &&
        <a className="header__anchor--feedback-survey" href={marketingUrl} target="_blank" rel="noopener noreferrer">
          Marketing Materials
        </a>
        }
        <a className="header__anchor--feedback-survey" href="https://www.surveymonkey.com/r/2VZKS7F" target="_blank" rel="noopener noreferrer">
          Have feedback?
        </a>
      </div>
      {props.children}
    </div>
  );
};

export default Header;
