import React from 'react';
import './css/LoginHeader.css';
import Logo from '../../assets/images/login-meritize-logo.svg';

const LoginHeader = (props) => {
    return (
        <div className="login-header">
            <a href={props.homeLink || "/"}>
                <img className="desktop-logo"  alt="Meritize" src={Logo} />
            </a>
          <div className="login-header__div--feedback-survey">
            <a className="login-header__anchor--feedback-survey" href="https://www.surveymonkey.com/r/2VZKS7F" target="_blank" rel="noopener noreferrer">
              Have feedback?
            </a>
          </div>
        </div>
    );
};

export default LoginHeader;
