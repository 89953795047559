import React from 'react';
import './css/LoadingIndicator.css';
import LoadingIcon from '../../assets/images/meritize-loading-indicator.gif';
import Box from "@material-ui/core/Box";

class LoadingIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.showIndicator = this.showIndicator.bind(this);

    this.state = {
      enabled: false,
    };

    this.timer = setTimeout(this.showIndicator, 300);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  showIndicator() {
    this.setState({enabled: true})
  }

  render() {
    const {enabled} = this.state;

    if (!enabled) {
      return null;
    }

    return (
      <Box className="loading-indicator__container" data-testid="main__loading-indicator" color="text.primary">
        <img className="loading-indicator__icon" alt="Meritize-Loading" src={LoadingIcon}/>
        <div className="loading-indicator__text">Loading please wait.....</div>
      </Box>
    );
  }
}

export default LoadingIndicator;