import React, {useState} from 'react';
import './css/PrimText.css';

function PrimText (props) {
  props = {...props, className: props.className + ' prim-text'}
  const {children} = props;

  delete props.children;

  let [hover, setHover] = useState(false);
  let [timeoutHandle, saveTimeoutHandle] = useState(null);

  return (
      <div {...props}>
        {hover && <div
          className="prim-text__tooltip"
          data-testid="prim-tt"
        >{children}</div>}
        <div className="prim-text__text"
             data-testid="prim-text"
             onMouseEnter={() => {
               timeoutHandle && clearTimeout(timeoutHandle);
               saveTimeoutHandle(setTimeout(() => setHover(true), 400));
             }}
             onMouseLeave={() => {
               clearTimeout(timeoutHandle);
               saveTimeoutHandle(null);
               setHover(false);
             }}
        >{children}</div>
      </div>
  );
}

export default PrimText;
