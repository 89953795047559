// Take a date in YYYY-MM-DD format and convert to M/D/YY format.
// Does not do true validation (invalid dates like 2020-56-99 will still be converted).
export const formatDate = (d) => {
  if (d == null) {
    return '-/-/--';
  }

  let dateRe = /^\d\d(\d\d)-(\d\d)-(\d\d)$/;
  let match = dateRe.exec(d);
  if (match === null) {
    throw new Error('Invalid date string: \'' + d + '\'');
  }
  let [, yearStr, monthStr, dayStr] = match;
  let month = parseInt(monthStr, 10);
  let day = parseInt(dayStr, 10);
  return `${month}/${day}/${yearStr}`;
};

// Given a date with timestamp in format 'YYYY-MM-DD HH:MM:SS', strip off the
// time part (return 'YYYY-MM-DD')
export const stripTime = (d) => {
  return d.substring(0, 10);
};

export const formatDollarAmount = (amt, options) => {
  const moneyFormatter = Intl.NumberFormat('en-US',
    {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      ...options,
    });

  if (amt === null || amt === undefined) {
    return '';
  }
  let strAmt = moneyFormatter.format(amt).replace(/\.\d$/, (m) => m + '0');
  return strAmt;
};

export const formatPreciseDollarAmount = (amt) => formatDollarAmount(amt, {minimumFractionDigits: 2});

export const formatCampusDisplayNameFromApplication = (application) => {
  return formatCampusDisplayName(application.isProgramOnline,
    application.campusCity, application.campusState, application.campusName);
};

export const formatCampusDisplayNameFromCampus = (campus) => {
  return formatCampusDisplayName(campus.isOnline, campus.city, campus.state,
    campus.name);
};

export const formatCampusDisplayName = (isOnline, city, state, name) => {
  if (isOnline) {
    return 'Online';
  }
  if (state === null || state === '') {
    return name;
  }
  return `${city}, ${state}`;
};

export const formatPersonName = (name) => {
  const {firstName, middleInitial, lastName} = name;
  if (!!middleInitial) {
    const mi = middleInitial.replace(/\W+/g, '');
    return `${firstName} ${mi}. ${lastName}`;
  }
  return `${firstName} ${lastName}`;
};
