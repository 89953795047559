import {FakeMixpanelClient, MixpanelClient} from './MixpanelClient';
import {Auth} from './withAuth';

export default class UserInfo {
  constructor(auth) {
    this.auth = auth || Auth;
    this.setAlternativeUser = this.auth.setAlternativeUser.bind(auth)
    this.removeAlternativeUser = this.auth.removeAlternativeUser.bind(auth)
  }

  getSubject()     { return this.executeOnToken((token) => token.username); }
  getSchoolNames() { return this.executeOnToken((token) => token.schoolNames); }
  getSchoolIds()   { return this.executeOnToken((token) => token.schoolIds); }
  getOrgNames()    { return this.executeOnToken((token) => token.orgNames); }
  isAdmin()        { return this.executeOnToken((token) => !!token.meritizeAdmin); }
  isMeritizeStaff(){ return !this?.getSchoolIds()?.length; }

  getMixpanelClient(){
    return this?.isMeritizeStaff()
      ? new FakeMixpanelClient()
      : new MixpanelClient();
  }

  getCurrentUsername(){
    return this.auth.getPermissions().username
  }

  executeOnToken(accessor) {
    const token = this.auth.getPermissions();
    return (token != null ? accessor(token) : null)
  }

  hasMultipleSchools() {
    return (this.getSchoolIds() || []).length > 1;
  }

  hasPermissionForSchool(permissions, schoolId, permissionName) {
    if (permissions.meritizeAdmin) return true;
    const schoolPerms = permissions.schoolPermissions || [];
    if (schoolId === 'all') {
      for (let i = 0; i < schoolPerms.length; i++) {
        if (!!!schoolPerms[i].permissions[permissionName] && !!!schoolPerms[i]['admin']) {
          return false;
        }
      }
      return true;
    }
    if (typeof schoolId !== 'number') {
      throw new Error("schoolId must be a number");
    }
    for (let i = 0; i < schoolPerms.length; i++) {
      if (schoolPerms[i].schoolId === schoolId) {
        if (schoolPerms[i].permissions[permissionName] || schoolPerms[i]['admin']) {
          return true;
        }
      }
    }
    return false;
  }

  canRefundForSchool(schoolId) {
    return this.executeOnToken(permissions =>
      this.hasPermissionForSchool(permissions, schoolId, 'refund'));
  }

  canDownloadRiskShareReports() {
    return this.executeOnToken(permissions =>
        this.hasPermissionForSchool(permissions, (permissions.schoolIds || [])[0], 'risk_share_report')
    );
  }

  canEvForAnySchool() {
    return this.executeOnToken(permissions =>
      !!permissions.meritizeAdmin ||
      permissions.schoolIds.some(schoolId =>
        this.hasPermissionForSchool(permissions, schoolId, 'enrollment_verification')
      )
    );
  }

  canEvForSchool(schoolId) {
    return this.executeOnToken(permissions =>
      !!permissions.meritizeAdmin || this.hasPermissionForSchool(permissions, schoolId, 'enrollment_verification')
     );
  }

  _canViewBasicInfoForSchool(permissions, schoolId) {
    return !!permissions.meritizeAdmin || this.hasPermissionForSchool(permissions, schoolId, 'view_basic_info');
  }

  canViewBasicInfoForAllAssignedSchools() {
    return this.executeOnToken(permissions => {
      return !!permissions.meritizeAdmin || permissions.schoolIds.every(schoolId => this._canViewBasicInfoForSchool(permissions, schoolId));
    });
  }

  _canDownloadDisbursementReportForSchool(permissions, schoolId) {
    return !!permissions.meritizeAdmin || this.hasPermissionForSchool(permissions, schoolId, 'disbursement_reports');
  }

  canDownloadDisbursementReportForAllAssignedSchools() {
    return this.executeOnToken(permissions => {
      return !!permissions.meritizeAdmin || permissions.schoolIds.every(schoolId => this._canDownloadDisbursementReportForSchool(permissions, schoolId));
    });
  }

  getDaysToSendMoneyBack(schoolId) {
    if(this.isAdmin()){
      return 14
    }

      const schoolParams = this.auth.getPermissions().schoolParameters || [];
      for (let i = 0; i < schoolParams.length; i++) {
        if (schoolParams[i].schoolId === schoolId) {
          return schoolParams[i].daysToSendMoneyBack
        }
      }
      throw new Error("schoolId "+schoolId+" must exist in parameters list");
  }
}
