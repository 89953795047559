import React from 'react';
import Card from "@material-ui/core/Card";
import "./css/LoanInfo.css";
import PrimText from '../PrimText/PrimText';

function LoanInfo (props) {
  let {application} = props;

  let repaymentOption = {
    'defer': 'Fully Deferred',
    'interest': 'Interest Only',
    'full': 'Full Payments',
    'cpl': 'Commercial Pilot’s License',
  }[application.repaymentOption]

  let creditTier = (application.creditTier || '').replace(/^T/, '');

  return (
    <Card variant="outlined" className="loan-info__card card margin_top_small margin_bottom_small">
      <div className="card-title">Loan Information</div>
      <div className="loan-info">
        <div className="loan-info-row loan-info-header">
          <div className="term-length-header">Term Length</div>
          <div className="credit-tier-header">Credit Tier</div>
          <div className="repayment-option-header">Repayment Option</div>
          <div className="ev-header">Enrollment Verified</div>
        </div>
        <div className="loan-info-row">
          <div className="term-length-value emphasized">{application.termLength || '\u00a0'} Months</div>
          <div className="credit-tier-value emphasized">{creditTier || '\u00a0'}</div>
          <PrimText className="repayment-option-value emphasized">{repaymentOption || '\u00a0'}</PrimText>
          <div className="ev-value emphasized" data-testid="loan-info-enrollment-verified">{application.enrollmentVerified ? "Yes" : "No"}</div>
        </div>
      </div>
    </Card>
  );
}

export default LoanInfo;
