import React, { Component } from 'react';
import "./css/MaintenancePage.css";
import ToolboxImg from '../../assets/images/toolbox.svg';
import Header from "../../component/Header/Header";

class MaintenancePage extends Component {

    render() {
        return (
            <div>
                <Header />
                <div className="maintenance-page__container">
                    <img className="maintenance-page__toolbox-logo" alt="Site Under Maintenance" src={ToolboxImg} />
                    <div className="maintenance-page__header">We’re upgrading our tools…</div>
                    <div className="maintenance-page__message">We are currently undergoing scheduled maintenance. We will be back shortly. Thank you for your patience!</div>
                </div>
            </div>
        );
    }
};


export default MaintenancePage;