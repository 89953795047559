import React, {memo, useCallback} from "react";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {REFUND_ONLY, REFUND_WITHDRAWAL, WITHDRAWAL_ONLY} from './Constants';

const RefundReason = memo(({reason, optionNames, onChange, eventType}) => {
  const handleChange = useCallback((e) => onChange(e.currentTarget.value), [onChange])

  function getRefundReasonLabelText() {
    switch (eventType) {
      case WITHDRAWAL_ONLY:
        return 'Reason for Withdrawal';
      case REFUND_ONLY:
        return 'Reason for Refund';
      case REFUND_WITHDRAWAL:
        return 'Reason for Refund/Withdrawal';
      default:
        throw new Error("Unexpected event type: " + eventType);
    }
  }

  return (
    <div className="refund-form__refund-reason">
      <FormLabel classes={{root: "refund-reason__label required"}}
                 htmlFor="refund-reason-selector"
                 data-testid="refund-reason-dropdown">{
                   getRefundReasonLabelText()
                }
        </FormLabel>
      <FormControl variant="outlined"
                   classes={{
                     root: "refund-reason__select-control",
                   }}>
        <Select
          native
          classes={{select: "refund-reason__select"}}
          value={reason}
          inputProps={{
            'id': 'refund-reason-selector',
          }}
          onChange={handleChange}
        >
          <option key='default' value=''></option>
          {
            optionNames.map((opt, i) =>
              <option key={i} value={opt} data-testid='refund-reason-option'>{opt}</option>,
            )
          }
        </Select>
      </FormControl>
    </div>
  );
});

export default RefundReason;
