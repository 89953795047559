import React from 'react';
import './css/ColumnHeaderCard.css';
import '../css/ApplicationList.css';
import Box from "@material-ui/core/Box";


export default function ColumnHeaderCard() {
  return (
    <Box color="text.dark">
      <div data-testid="column-header-card" className="column-header">
        <div className="column-header__applicant header-section header-title">Student Info</div>
        <div className="column-header__program header-section header-title">Program</div>
        <div className="column-header__loan header-section">
          <div className="header-title">Application</div>
          <div className="header-subtext">(by recent)</div>
        </div>
        <div className="column-header__status header-section header-title">Status</div>
        <div className="column-header__disbursement header-section header-title">Disbursements</div>
      </div>
    </Box>
  );
}
