
export default class Inspectlet {
  _insp() {
    return window.__insp;
  }

  identify(subject) {
    this._insp().push(['identify', subject]);
  }

  tag(arg) {
    this._insp().push(['tagSession', arg]);
  }
}
