import React, {memo} from 'react';
import PropTypes from 'prop-types';
import './css/Toast.css'
import {Snackbar} from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import {withStyles} from "@material-ui/styles";
import MuiAlert from '@material-ui/lab/Alert';
import {useStateIfMounted} from 'use-state-if-mounted';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const styles = {
  "@global": {
    ".MuiSnackbarContent-root": {
      backgroundColor: "#2F78D5",
      flexWrap: "unset"
    },
    ".MuiSnackbarContent-action": {
      paddingLeft: "0"
    }
  }
};

export const ToastMessage = (props) => {
  return <div id="message-id" className={'message'}>
    <span dangerouslySetInnerHTML={{ __html: props.message }} />
  </div>;
};

export const Toast = (props) => {

  const [toasts, setToasts] = useStateIfMounted([])

  const addToast = (msg, severity='info', onClose) => setToasts(toasts.concat([{
    id: Math.random(), msg, severity, onClose
  }]))

  const closeToast = (id) => setToasts(toasts.filter(toast => {
    if(toast.id !== id){
      return true
    }
    !!toast.onClose && toast.onClose()
    return false
  }))

  React.useEffect(() => {
    props.displayToast.current = addToast
  } )

  const ToastItem = (props) => {
    return (
      <Alert key={props.id}
             severity={props.severity}
             action={[
               <IconButton data-testid="close-icon"
                           key="close"
                           aria-label="close"
                           color="inherit"
                           className={'closeIcon'}
                           onClick={() => closeToast(props.id)}>
                 <CloseIcon/>
               </IconButton>,
             ]}>
        <ToastMessage
          message={props.msg}
        /></Alert>
    )
  }

  const MemoToastItem = memo(ToastItem)


  return (
    <Snackbar
      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
      key={Math.random()}
      open={!!toasts.length}
      ClickAwayListenerProps={{
        mouseEvent: false,
        touchEvent: false
      }}
      TransitionProps={{
        appear: false,
      }}
      autoHideDuration={props.timeout}
      action={[
        <IconButton data-testid="close-icon"
                    key="close"
                    aria-label="close"
                    color="inherit"
                    className={'closeIcon'}
                    onClick={props.onClose}>
          <CloseIcon/>
        </IconButton>,
      ]}
    >
      <div>
        { toasts.map( toast =>
          <MemoToastItem id={toast.id} severity={toast.severity} msg={toast.msg}/>)
        }
      </div>

    </Snackbar>
);
};

Toast.propTypes = {
  displayToast: PropTypes.func.isRequired
};

export default withStyles(styles)(Toast);
