import React, {memo} from "react";
import {IconTooltip} from "component/IconTooltip/IconTooltip";
import {formatPreciseDollarAmount} from "util/format";
import UserInfo from "../../util/UserInfo";
import {Auth} from "../../util/withAuth";

const userInfo = new UserInfo(Auth);

const RefundCalculatorDisplay = memo((props) => {
    const {refundValues, showLoanDiscount, schoolId} = props;

    const getSentToMeritizeTooltip = () => {
      if (schoolId === 'all') {
        return "Please refer to your refund receipt email for the deadline to submit your refund by."
      }
      return "This is the amount your school will be responsible for sending to Meritize. These funds should be sent within " + userInfo.getDaysToSendMoneyBack(schoolId) + " days from the submission of this form."
    }

    return (
        <>
            <h4 className="refund-form__section-title">Refund Calculator:</h4>
            <div className="refund-calc">
                <div className="refund-calc__section">
                    <label
                        htmlFor="refund-calc-total-amount-disbursed"
                        className="refund-calc__label">Disbursed amount
                        <IconTooltip
                            data-testid="tooltip-disbursed-amount"
                            placement="bottom-start"
                            title="This is the amount that has been disbursed to your school to date."
                        />
                    </label>
                    <input
                        disabled
                        id="refund-calc-total-amount-disbursed"
                        className="refund-calc__value"
                        value={formatPreciseDollarAmount(refundValues.totalAmountDisbursed)}/>
                    <label
                        htmlFor="refund-calc-amount-used"
                        className="refund-calc__label">Disbursed amount used
                      <IconTooltip
                        data-testid="tooltip-disbursed-amount-used"
                        placement="bottom-start"
                        title="This will be reflected as the student’s new applied to tuition amount."
                      />
                    </label>
                    <input
                        disabled
                        id="refund-calc-amount-used"
                        type="text" className="refund-calc__value"
                        value={formatPreciseDollarAmount(refundValues.amountUsed)}/>
                    <label
                        htmlFor="refund-calc-total-refund"
                        className="refund-calc__label">Total refund amount</label>
                    <input
                        disabled
                        id="refund-calc-total-refund"
                        className="refund-calc__value"
                        value={formatPreciseDollarAmount(refundValues.totalRefundAmount)}/>
                    <label
                        htmlFor="refund-calc-risk-share-refund"
                        className="refund-calc__label"
                    >Risk share amount
                        <IconTooltip
                            data-testid="tooltip-risk-share-amount"
                            placement="top-start"
                            title="This is the amount Meritize will release from the risk share."
                        />
                    </label>
                    <input
                        disabled
                        id="refund-calc-risk-share-refund"
                        className="refund-calc__value"
                        value={formatPreciseDollarAmount(refundValues.riskShareRefund)}/>
                    {showLoanDiscount &&
                    <>
                        <label
                            htmlFor="refund-calc-loan-discount-refund"
                            className="refund-calc__label">Loan discount refund</label>
                        <input
                            disabled
                            id="refund-calc-loan-discount-refund"
                            className="refund-calc__value"
                            value={formatPreciseDollarAmount(refundValues.loanDiscountRefund)}/>
                    </>
                    }

                </div>
                <div className="refund-calc__section">
                    <p className="refund-calc__label refund-calc__label--primary">
                        <label htmlFor="refund-calc-amount-to-send">Amount to send to Meritize
                            <IconTooltip
                                data-testid="tooltip-sent-to-meritize"
                                placement="top-end"
                                title={getSentToMeritizeTooltip()}
                            /></label>
                    </p>
                    <input
                        disabled
                        id="refund-calc-amount-to-send"
                        className="refund-calc__value refund-calc__value--primary"
                        value={formatPreciseDollarAmount(refundValues.amountToSend)}/>
                </div>
            </div>
        </>);
});

export default RefundCalculatorDisplay;
