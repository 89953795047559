import React, {Component} from 'react';
import AuthService from 'util/AuthService';
import Environment from 'util/Environment';

const Auth = new AuthService(Environment.apiUrl());
export {Auth};

const queryParamsToObject = (params) => {
  const query = params.substr(1);
  const result = {};
  query.split('&').forEach(function (part) {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};

export default function withAuth(AuthComponent) {
  return class AuthWrapped extends Component {
    constructor() {
      super();
      if (!Auth.loggedIn()) {
        this.state = {
          loggedIn: false
        }
      } else {
        this.state = {
          loggedIn: true,
        }
      }
    }

    componentDidMount() {
      if (!Auth.loggedIn()) {
        if (queryParamsToObject(document.location.search).appId) {
          localStorage.setItem('appIdSearch', queryParamsToObject(document.location.search).appId)
        }
        this.props.history.replace('/login')
      }
    }

    render() {
      if (this.state.loggedIn) {
        return (
          <AuthComponent history={this.props.history} user={this.state.user} {...this.props} />
        )
      } else {
        return null
      }
    }
  }
}
