import React from 'react';
import Card from "@material-ui/core/Card";
import {formatDate} from "../../util/format";
import './css/ApplicantDetails.css'

function ApplicantDetails (props) {
  let {application} = props;

  return (
    <Card variant="outlined" className="card margin_top_small applicant-details__card">
      <div className="card-title">Personal Information</div>
      <div className="inspectlet-sensitive">
        <div className="applicant-details__text">{application.lastName}, {application.firstName}{application.middleInitial}</div>
        <div className="applicant-details__text">{application.phone}</div>
        <div className="applicant-details__text emphasized">{application.email}</div>
        <div className="applicant-details__text">SSN: <span className="emphasized">{application.ssn}</span></div>
        <div className="applicant-details__text">DOB: <span className="emphasized">{formatDate(application.dob)}</span></div>
      </div>
    </Card>
  );
}

export default ApplicantDetails;
