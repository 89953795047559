import React, {useState} from 'react';
import validate from 'validate.js';
import './css/SearchBox.scss';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CTAButton from '../CTAButton/CTAButton';
import StatusFilterBox from "../StatusFilterBox/StatusFilterBox";
import FilterInactiveSvg from "../../assets/images/filter_inactive.svg";
import FilterActiveSvg from "../../assets/images/filter_active.svg";

const SearchBox = (props) => {
  let { placeholder, disabled, onSearch, onChangeValue, value, checkedStatuses, setCheckedStatuses, statusList, loading } = props;

  let [showStatusFilter, setShowStatusFilter] = useState(false);

  const inputRef = React.createRef();

  const looksLikeAppId = (text) => {
    return /^\s*\d+\s*$/.test(text);
  }

  const looksLikeEmail = (text) => {
    const emailConstraints = {
      searchVal: {
        email: true,
      },
    };
    return !validate({searchVal: value}, emailConstraints);
  }

  const onSearchPressed = (value, triggeredByEnterKey) => {
    if(disabled){
      return;
    }
    if(inputRef?.current){
      inputRef.current.blur();
    }
    if (looksLikeAppId(value)) {
      return onSearch({query: value.trim(), type: 'appId', triggeredByEnterKey});
    }
    if (looksLikeEmail(value)) {
      return onSearch({query: value.trim(), type: 'email', triggeredByEnterKey});
    }
    const strippedValue = value.trim().replace(/[.,]/g, ' ').replace(/ +/g, ' ');

    if (strippedValue === '') {
      //reset search when value is blank.
      return onSearch({query: '', type: null, triggeredByEnterKey});
    }
    // search by name in this case
    return onSearch({query: strippedValue, type: 'name', triggeredByEnterKey});
  };

  const toggleShowStatusFilter = () => {
    setShowStatusFilter(!showStatusFilter);
  }

  return (
    <div className="search-box">
     <div className="search-box__container">
       <TextField
          variant="outlined"
          placeholder={placeholder}
          value={value}
          InputProps={{
            startAdornment: <InputAdornment position="start">
              <SearchIcon fontSize="small"/>
            </InputAdornment>,
          }}
          onChange={(e) => onChangeValue(e.target.value)}
          inputProps={{
            'ref': inputRef,
            'data-testid': 'search-box-input',
          }}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              onSearchPressed(value, true);
            }
          }}
          disabled={disabled}
        />

        {
          !loading &&
            <div className="status-filter-box--icon-container" onClick={toggleShowStatusFilter} data-testid="status-filter-box-button">
              { !statusList.length
                ? <img alt="filter-inactive-img" src={FilterInactiveSvg} data-testid="filter-inactive-img"/>
                : <img alt="filter-active-img" src={FilterActiveSvg} data-testid="filter-active-img"/>
              }
            </div>
        }
       {
         !!loading &&
         <div className="status-filter-box--icon-container" >
           { !statusList.length
             ? <img alt="filter-inactive-img-dis" src={FilterInactiveSvg} />
             : <img alt="filter-active-img-dis" src={FilterActiveSvg} />
           }
         </div>
       }
     </div>

      <CTAButton
        data-testid="search-box-search-button"
        onClick={() => onSearchPressed(value, false)}
        disabled={disabled}
      >Search</CTAButton>

      {
        showStatusFilter
          ?
          <StatusFilterBox toggleShowStatusFilter={toggleShowStatusFilter}
                           onApply={() => onSearchPressed(value, false)}
                           checkedStatuses={checkedStatuses}
                           setCheckedStatuses={setCheckedStatuses} />
          : <></>
      }
    </div>
  );
};

SearchBox.propTypes = {
  placeholder: PropTypes.string,
};

export default SearchBox;
