import React from 'react';
import Typography from "@material-ui/core/Typography";
import CelebrationSvg from './assets/Celebration.svg';
import TimerSvg from './assets/Timer.svg';
import './css/NoResults.css';

export default function NoResults(props){

  let {campusKey, showOnlyEvs, searchQuery, statusFilterApplied} = props;

  let genericNoResultHeaderMessage = "Sorry, we couldn’t find any matches";

  let determineText = () => {
    if(searchQuery || statusFilterApplied){
      if(showOnlyEvs) {
        return {header: genericNoResultHeaderMessage, subheader:"Try turning off the EV toggle to display more results"}
      }
      else if(campusKey.includes("all")) {
        return {header: genericNoResultHeaderMessage, subheader:"Try adjusting your criteria to display more results."}
      }
    }else{
      if(showOnlyEvs) return {
        header: "Way to go, there are currently no outstanding EV’s!",
        subheader:"",
        alt: "celebration",
        image: CelebrationSvg,
      };
      if(campusKey && campusKey.includes('all'))
        return {
          header:"Hold tight, no applicants yet, but we know they'll be here soon!",
          subheader: "",
          alt: "timer",
          image: TimerSvg,
        };
    }
      return {header: genericNoResultHeaderMessage, subheader:"Try changing your campus to display more results."};
  };

  let textToDisplay = determineText();

  return (<div className="no-results-container" data-testid="no-results">
            <div className="no-results-container__header-container" style={{}}>
              <img alt={textToDisplay.alt} src={textToDisplay.image} />
              <Typography variant={"h6"} className="no-results-container__sub-header-container">{textToDisplay.header}</Typography>
            </div>
            <Typography>{textToDisplay.subheader}</Typography>
        </div>);
}
