import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import './css/paging.css';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const Paging = (props) => {
  let callClick = (pageNumber, leftOrRight) => {
    if ((props.first === true && leftOrRight === 'left')
      || (props.last === true && leftOrRight === 'right')) {
      return;
    }
    props.onClick(pageNumber);
  };
  return (<div className='paging-component'>
    <div className="paging-component__internals">
      <Fab className={(props.first === true ?
        'pagination__previous-button-disabled' :
        'pagination__previous-button')}
           onClick={() => callClick(Number(props.currentPage) - 1, 'left')}
           disabled={(props.first === true)}
      >
        <KeyboardArrowLeftIcon className={
          (props.first === true ? 'paging-component__disabled' : 'paging-component__active')}
                               fontSize='large'
                               data-testid={'paging-indicator-prev'}
        />
      </Fab>

      <div className="pagination__page-number">
        <span data-testid={'paging-indicator-currentPage'}>{Number(props.currentPage) + 1}</span> of <span data-testid={'paging-indicator-totalPages'}>{props.totalPages}</span>
      </div>

      <Fab className={(props.last === true ?
        'pagination__next-button-disabled' :
        'pagination__next-button')}
           onClick={() => callClick(Number(props.currentPage) + 1, 'right')}
           disabled={(props.last === true)}>
        <KeyboardArrowRightIcon className={(props.last === true ?
          'paging-component__disabled' :
          'paging-component__active')} fontSize='large'
                                data-testid={'paging-indicator-next'}
        />
      </Fab>
    </div>
  </div>);
};

Paging.propTypes = {
  placeholder: PropTypes.objectOf(PropTypes.shape({
    first: PropTypes.bool,
    last: PropTypes.bool,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    onClick: PropTypes.func,
  })),
};

export default Paging;

