import React from 'react';
import {formatDate} from '../../util/format.js';
import {formatCampusDisplayNameFromApplication} from "../../util/format";
import './css/ProgramSummary.css';
import PrimText from "../PrimText/PrimText";

function ProgramSummary (props) {
  let {application} = props;

  return(
    <React.Fragment>
      <PrimText className="application-summary__field--strong">{application.programName}</PrimText>
      {
        application.schoolName &&
          <PrimText>{application.schoolName}</PrimText>
      }
      <div data-testid="campus">{formatCampusDisplayNameFromApplication(application)}</div>
      <div data-testid="program-summary-enrollment-period">
        {formatDate(application.startDate)}-{formatDate(application.gradDate)}
      </div>
    </React.Fragment>
  );
}

export default ProgramSummary;
