import React from "react";
import "./css/DisbursementSummary.css";
import "../ApplicationList/ApplicationCard/css/ApplicationCard.css";
import {formatDate,formatDollarAmount} from "../../util/format";
import CheckIcon from '@material-ui/icons/Check';

function DisbursementSummary(props) {
  let {disbursements} = props;
  disbursements = disbursements === undefined ? [] : disbursements;

  return (
    <table className="disbursement-summary">
      <tbody>
        {
          disbursements.map((disbursement, index) =>
            <tr data-testid={`disbursement-summary-disbursement-${index+1}`} key={index}>
              <td className="disbursement-summary__check-cell">
                {disbursement.disbursed ?
                  <CheckIcon className="disbursement-summary__check-icon"/> : null
                }
              </td>
              <td className="disbursement-summary__amount" data-testid={`disbursement-amount-${index+1}`}>
                <div>{formatDollarAmount(disbursement.amount.total)}</div>
              </td>
              <td className="disbursement-summary__date" data-testid={`disbursement-date-${index+1}`}>
                <div>{formatDate(disbursement.date)}</div>
              </td>
            </tr>
        )
      }
      </tbody>
    </table>
  )
};

export default DisbursementSummary;
