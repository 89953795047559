import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";

import Login from "./pages/Login/Login";
import Main from "./pages/Main/Main";
import { Auth } from "util/withAuth.js";
import Environment from './util/Environment';
import MaintenancePage from "./pages/MaintenancePage/MaintenancePage";

import { enableAllPlugins } from "immer";
import UserInfo from "./util/UserInfo";
import Inspectlet from "./util/Inspectlet";
import "App.scss";
import {StylesProvider} from "@material-ui/core/styles";
import ChangePassword from "./component/ChangePassword/ChangePassword";

const userInfo = new UserInfo(Auth);

enableAllPlugins();

export const setInspectletIdentity = () => {
  const inspectlet = new Inspectlet();
  const subject = userInfo.getSubject();

  if (!!subject) {
    inspectlet.identify(subject);
  }
  const schoolNames = userInfo.getSchoolNames();
  if (!!schoolNames) {
    schoolNames.forEach((name) => {
      inspectlet.tag(`school: ${name}`);
    });
  }
  const orgNames = userInfo.getOrgNames();
  if (!!orgNames) {
    orgNames.forEach((name) => {
      inspectlet.tag(`organization: ${name}`);
    });
  }
};

// Begin Inspectlet Asynchronous Code
(function () {
  window.__insp = window.__insp || [];
  window.__insp.push(['wid', 1174159644]);
  var ldinsp = function () {
    const header = document.getElementsByTagName('head')[0];
    if (header === undefined) return;
    if (typeof window.__inspld != "undefined") return;
    window.__inspld = 1;
    const insp = document.createElement('script');
    insp.type = 'text/javascript';
    insp.async = true;
    insp.id = "inspsync";
    insp.src = ('https:' === document.location.protocol ? 'https' : 'http') +
      '://cdn.inspectlet.com/inspectlet.js?wid=1174159644&r=' + Math.floor(new Date().getTime() / 3600000);
    header.appendChild(insp);
    setInspectletIdentity(window.__insp);
  };
  window.addEventListener('load', (event) => ldinsp());
})();
// End Inspectlet Asynchronous Code

class App extends Component {
  render = () =>
    Environment.redirectToMaintenance() ?
      <MaintenancePage /> :
      <StylesProvider injectFirst>

      <div>
        <Router>
          <Route path="/" exact render={(props) => <Main {...props}/>} />
          <Route path="/login" exact render={(props) => <Login onLogin={() => setInspectletIdentity(window.__insp)} {...props} />} />
          <Route path="/reset_password_verification" exact render={(props) => <ChangePassword {...props}/>}/>
        </Router>
      </div>
      </StylesProvider>
}

export default App;
