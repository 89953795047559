export const REFUND_ONLY       = 'refund';
export const REFUND_WITHDRAWAL = 'refund_and_withdrawal';
export const WITHDRAWAL_ONLY   = 'withdrawal';

export const REFUND_ONLY_REASON_OPTIONS = [
  "Dropped classes",
  "Return of books/equipment/tools",
  "Credit on account",
]

export const REFUND_WITHDRAWAL_REASON_OPTIONS = [
  "Full School Withdrawal",
  "Dropped classes",
  "Return of books/equipment/tools",
  "Credit on account",
]

export const WITHDRAWAL_ONLY_REASON_OPTIONS = [
  "Full School Withdrawal",
  "Dropped classes",
  "Return of books/equipment/tools",
  "Credit on account",
]

export const REFUND_REASONS_MAP = {
  [REFUND_ONLY]: REFUND_ONLY_REASON_OPTIONS,
  [REFUND_WITHDRAWAL]: REFUND_WITHDRAWAL_REASON_OPTIONS,
  [WITHDRAWAL_ONLY]: WITHDRAWAL_ONLY_REASON_OPTIONS
}
