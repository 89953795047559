import React, {useEffect, useRef, useState} from 'react';
import DateFnsUtils from '@date-io/date-fns';
import Container from '@material-ui/core/Container';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {MuiThemeProvider, withStyles} from '@material-ui/core';
import {createTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import {isEqual} from 'lodash';

import ApplicationCard from 'component/ApplicationList/ApplicationCard/ApplicationCard';
import withAuth, {Auth} from 'util/withAuth';
import EnrollmentVerificationForm from 'component/EnrollmentVerificationForm/EnrollmentVerificationForm';
import CampusSelector from 'component/CampusSelector/CampusSelector';
import Header from 'component/Header/Header';
import LoadingIndicator from 'component/LoadingIndicator/LoadingIndicator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';
import Switch from '@material-ui/core/Switch';
import './Main.scss';
import SearchBox from "component/SearchBox/SearchBox";
import ColumnHeaderCard from "component/ApplicationList/ColumnHeaderCard/ColumnHeaderCard";
import Toast from "component/Toast/Toast";
import Paging from 'component/Paging/paging';
import SchoolSelector from 'component/SchoolSelector/SchoolSelector';
import Button from "@material-ui/core/Button";
import NoResults from "component/NoResults/NoResults";
import {formatCampusDisplayNameFromCampus} from "util/format";
import UserInfo from "util/UserInfo";
import Inspectlet from "util/Inspectlet";
import Environment from "util/Environment";
import RefundForm from "../../component/RefundForm/RefundForm";
import Paper from "@material-ui/core/Paper";
import {API} from "util/APIClient";
import RiskShareReportMenu from "../../component/RiskShareReportMenu/RiskShareReportMenu";
import GetApp from '@material-ui/icons/GetApp';
import usePrevious from '../../util/usePrevious';
import useStateWithPromise from '../../util/useStateWithPromise';
import TextField from "@material-ui/core/TextField";
import CTAButton from "../../component/CTAButton/CTAButton";
import useEffectIfMounted from '../../util/useEffectIfMounted';
import IconExclaimSvg from "../../assets/images/icon-exclaim.svg";

const userInfo = new UserInfo(Auth);

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          'border-color': '#586a76',
          'border-width': '1px',
          'outline': 'none',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#3c89e8', // ocean-blue
    },
    text: {
      primary: '#586a76', // merit-gray-600
      secondary: '#586a76', // merit-gray-600
      disabled: '#a2adb4', // merit-gray-400
      dark: '#455967',
    },
    error: {
      main: '#c51616', // twizzle
    },
  },
  typography: {
    "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
  }
});

const LOADING_CAMPUSES_VAL = 'loading-campuses';

const EvOnlySwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },
    '&$checked + $track': {
      backgroundColor: '#0056b4', //ocean-blue-800
      opacity: 1,
    },
  },
  checked: {},
  track: {},
})(Switch);

let replaceApplication = (applications, newApp) => {
  let index = applications.findIndex((app) => app.appId === newApp.appId);
  return [
    ...applications.slice(0, index),
    newApp,
    ...applications.slice(index + 1),
  ];
};

const queryParamsToObject = (params) => {
  const query = params.substr(1);
  const result = {};
  query.split('&').forEach(function (part) {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};

const campusesFromResponseData = (respData) => {
  let campuses = respData.map(
    c => ({name: formatCampusDisplayNameFromCampus(c), campusId: c.campusId}),
  );
  campuses.sort((a, b) => a.name.localeCompare(b.name));
  return campuses;
};

class OneAtATime {
  constructor() {
    this.canceller = null;
  }

  start() {
    if (!!this.canceller) {
      this.canceller.cancel();
    }
    this.canceller = axios.CancelToken.source();
  }

  finish() {
    this.canceller = null;
  }

  token() {
    return this.canceller.token;
  }
}

const OneAppFetchAtATime = new OneAtATime();
const OneCampusFetchAtATime = new OneAtATime();

function Main(props) {
  let urlAppSearch = false;
  let appIdSeach = '';
  let eventType = '';
  if (localStorage.getItem("appIdSearch")) {
    urlAppSearch = true;
    appIdSeach = localStorage.getItem("appIdSearch");
    eventType = "ev-email";
  }

  const displayToastRef = React.useRef(null);
  const displayToast = (...args) => displayToastRef.current(...args)

  const firstPageState = () => {
    return ({
      pageNumber: 0,
      pageSize: Number(Environment.requestPageSize()),
    });
  };

  const [currentPage, setCurrentPage] = useState({
    pageNumber: null,
    pageSize: null,
    totalPages: null,
  });

  const ogUserIsAdmin = useRef(userInfo.isAdmin());

  const [studentApplications, setStudentApplications] = useState('');
  const [desiredPage, setDesiredPage] = useState(firstPageState());
  const [campuses, setCampuses] = useState(null);
  const [loading, setLoading] = useState(true);
  const [statusList, setStatusList] = useState([]);
  const [checkedStatuses, setCheckedStatuses] = useStateWithPromise([]);
  const [initialCampus, setInitialCampus] = useState(queryParamsToObject(document.location.search).campus);
  const [activeRefundApplication, setActiveRefundApplication] = useState(null);
  const [activeEvApplication, setActiveEvApplication] = useState(null);
  const [disbursementSchedule, setDisbursementSchedule] = useState(null);
  const [schools, setSchools] = useState(null);
  const [schoolId, setSchoolId] = useState(null);
  const [campusKey, setCampusKey] = useState(null);
  const [currentRefreshingApp, setCurrentRefreshingApp] = useState(null);
  const [showOnlyEvs, setShowOnlyEvs] = useState(false);
  const [searchBoxValue, setSearchBoxValue] = useState(queryParamsToObject(document.location.search).appId || appIdSeach); // the current contents of the search box
  const [search, setSearch] = useState({
    query: queryParamsToObject(document.location.search).appId || appIdSeach,
    type: urlAppSearch ? eventType : queryParamsToObject(document.location.search).event
  }); // the most recently executed search query and searchType
  const [availableRiskShareReports, setAvailableRiskShareReports] = useState(null);
  const [userMounted, setUserMounted] = useState(false);
  const announcementMessageDisplayed = useRef(false);

  const [secondaryUsernameInput, setSecondaryUsernameInput] = useState(null);
  const [hasAltUserPermissions, setHasAltUserPermissions] = useState(false);

  const prevDesiredPage = usePrevious(desiredPage);
  const prevStatusList = usePrevious(statusList);
  const prevSchoolId = usePrevious(schoolId);
  const prevCampusKey = usePrevious(campusKey);
  const prevShowOnlyEvs = usePrevious(showOnlyEvs);
  const prevSearch = usePrevious(search);

  const firstRender = useRef(true);
  if (firstRender.current) {
    Auth.addSubscriberCallback(() => {
      props.history.push('/login');
    });
    firstRender.current = false;
  }

  if (!Auth.loggedIn()) {
    props.history.replace('/login');
  }

  const handleCloseAnnouncement = () => {
    sessionStorage.setItem('announcementDismissed', 'true');
  };

  localStorage.removeItem("appIdSearch");

  if (Environment.featureFlagEnabled('dev_refund_quickload')) {
    setSearchBoxValue('FortyEight');
    setSearch({query: 'FortyEight', type: 'name'});
  }

  const loadAllSchools = React.useCallback(() => {
    Auth.fetch('/school/list',{params: {}
    }).then(res => {
      let schools = res.data.sort((a, b) => a.name.localeCompare(b.name));

      setSchools(schools);
      setSchoolId(userInfo.isAdmin() ? schools[0].schoolId : 'all');
    }).catch((err) => {
      if (!err.response) {
        console.warn(err);
        return;
      }
      if (err.response.status === 403) {
        handleLogout();
      }
    });
  }, []);

  useEffect(() => {
    if (userInfo.isAdmin() || (userInfo.getSchoolIds().length > 1)) {
      loadAllSchools();
    } else {
      setSchoolId(userInfo.getSchoolIds()[0]);
    }

    setUserMounted(true);
  }, [hasAltUserPermissions, loadAllSchools]);

  useEffect(() => {
    if (schoolId !== prevSchoolId && schoolId !== null) {
      if (schoolId === 'all') {
        setAllCampusesAllSchools();
      } else {
        loadCampuses();
      }
      if (userInfo.canDownloadRiskShareReports()) {
        loadAvailableRiskShareReports();
      }
    }
  });

  useEffectIfMounted(() => {
    if (campusKey !== LOADING_CAMPUSES_VAL) {
      if (campusKey !== prevCampusKey) {
        props.history.push('/?campus=' + campusKey);
        loadApplications();
      }
      else if (
        (showOnlyEvs !== prevShowOnlyEvs) ||
        !isEqual(search, prevSearch) ||
        !isEqual(desiredPage, prevDesiredPage) ||
        !isEqual(statusList, prevStatusList))
      {
        loadApplications();
      }
    }
  });

  useEffectIfMounted(() => {
    if (Environment.announcementMessage() &&
      !Environment.announcementExpired() &&
      !announcementMessageDisplayed.current &&
      sessionStorage.getItem('announcementDismissed') === null)
    {
      announcementMessageDisplayed.current = true;
      displayToast(Environment.announcementMessage(), 'info', handleCloseAnnouncement);
    }
  });

  const toggleEvOnlyApplications = (event) => {
    event.preventDefault();
    let newShowOnlyEvsState = !showOnlyEvs;
    userInfo.getMixpanelClient().trackOnlyShowEVsToggled(newShowOnlyEvsState);
    setShowOnlyEvs(newShowOnlyEvsState);
    setDesiredPage(firstPageState());
  };

  const handleLogout = () => {
    Auth.logout();
    props.history.replace('/login');
  };

  const onChangeSchool = (schoolId) => {
    setSchoolId(schoolId === 'all' ? schoolId : parseInt(schoolId, 10));
    setCampusKey(LOADING_CAMPUSES_VAL);
    setInitialCampus(null);
    setStatusList([]);
    setDesiredPage(firstPageState());
    setShowOnlyEvs(false);
    setSearch({query: '', type: null});
    setSearchBoxValue('');
    setLoading(true);
    setStudentApplications(null);
  };

  const onChangeCampus = (campusKey) => {
    userInfo.getMixpanelClient().trackCampusChanged(campusKey);
    setCampusKey(campusKey);
    setStatusList([]);
    setDesiredPage(firstPageState());
    setShowOnlyEvs(false);
    setSearch({query: '', type: null});
    setSearchBoxValue('');
  };

  const onPageClick = (pageNumber) => {
    setDesiredPage({...desiredPage, pageNumber: pageNumber});
  };

  const campusKeyAll = (schoolId) => {
    return `all-${schoolId}`;
  };

  const parseCampusKey = (key) => {
    let m1 = /^all-(all|\d+)$/.exec(key);
    if (m1) {
      return {parsedCampusKey: 'all', schoolId: m1[1]};
    }
    let m2 = /^\d+$/.exec(key);
    if (m2) {
      return {parsedCampusKey: key};
    }
    throw new Error(`Invalid campus key: ${key}`);
  };

  const loadApplications = () => {
    OneAppFetchAtATime.start();
    setLoading(true);
    setStudentApplications(null);

    let evOnlyFilter = showOnlyEvs ? {EvFilter: true} : {};
    let searchFilter = {
      email: search.type === 'email' ? search.query : null,
      name: search.type === 'name' ? search.query : null,
      appReference: search.type === 'appId' || search.type === 'ev-email' ? search.query : null,
    };

    if (search?.query && search.type === 'ev-email') {
      userInfo.getMixpanelClient().trackEvEmailNotificationLink(search.query);
    }

    const {schoolId, parsedCampusKey} = parseCampusKey(campusKey);

    Auth.fetch('/', {
      params: {
        ...evOnlyFilter,
        ...searchFilter,
        statusList: statusList.join(','),
        schoolIds: schoolId === null ? schoolId : schoolId === 'all' ? userInfo.getSchoolIds().join(',') : schoolId,
        campusId: parsedCampusKey === 'all' ? null : parsedCampusKey,
        pageNumber: desiredPage.pageNumber,
        pageSize: desiredPage.pageSize,
      },
      cancelToken: OneAppFetchAtATime.token(),
    }).then(res => {
      OneAppFetchAtATime.finish();

      let processedApps = userInfo.hasMultipleSchools() ? res.data.content : res.data.content.map(app => {
        let newApp = {...app};
        delete newApp.schoolName;
        return newApp;
      });

      if (userMounted) {
        setCheckedStatuses(statusList);
        setStudentApplications(processedApps);
        // TEMPORARY FOR DEVELOPMENT
        setActiveRefundApplication(
          Environment.featureFlagEnabled('dev_refund_quickload') ? processedApps[0] : null);
        setCurrentPage({
          first: res.data.first,
          last: res.data.last,
          pageNumber: res.data.number,
          totalPages: res.data.totalPages,
          pageSize: res.data.size,
        });
        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
    });
  };

  const onChangeSecondaryUsername = (username) => {
    setSecondaryUsernameInput(username);
  };

  const onSubmitSecondaryUsername = () => {
    if (!!secondaryUsernameInput) {
      userInfo.setAlternativeUser(secondaryUsernameInput, displayToast).then(() => {setHasAltUserPermissions(true)})
    }
  };

  const onBackToAdminUser = () => {
    userInfo.removeAlternativeUser().then(() => {setHasAltUserPermissions(false)})
  };

  const handleKeyPressOnSecondaryUserInput = (e) => e.key === 'Enter' ? onSubmitSecondaryUsername() : null;

  const maybeDisplaySecondaryUser = () => {
    return userMounted && (ogUserIsAdmin.current)
      ? (<div className="seocndary-username__control">
          { secondaryUserName() ?
            <CTAButton
              classAffix="header-logout__set-user"
              onClick={onBackToAdminUser}
            >Back to Admin</CTAButton>
            :
            <>
              <TextField
                variant="outlined"
                placeholder="School User Email View"
                data-testid="secondary-user-inputbox"
                value={secondaryUsernameInput}
                onChange={(e) => onChangeSecondaryUsername(e.target.value)}
                onKeyPress={handleKeyPressOnSecondaryUserInput}
              />
              <CTAButton
                classAffix="header-logout__set-user"
                onClick={onSubmitSecondaryUsername}
              >Set view</CTAButton>
            </>
          }
        </div>
        )
      : null;
  };

  const maybeDisplaySchoolSelector = () => {
    return userMounted && (userInfo.isAdmin() || (userInfo.getSchoolIds().length > 1))
      ? (<SchoolSelector
        schools={schools}
        schoolId={schoolId || ''}
        showAllSchoolsOption={!userInfo.isAdmin()}
        onChange={onChangeSchool}/>)
      : null;
  };

  const loadAvailableRiskShareReports = () => {
    API.fetchAvailableReportsList(schoolId).then(res => {
      const {availableReports} = res.data;
      if (userMounted) {
        setAvailableRiskShareReports(availableReports);
      }
    }).catch((err) => {
      console.log(err);
      if (!err.response) {
        console.warn(err);
        return;
      }
      if (err.response.status === 403) {
        handleLogout();
      }
    });
  };

  const setAllCampusesAllSchools = () => {
    setCampusKey(campusKeyAll('all'));
    setCampuses([{'name': 'All Campuses', campusId: campusKeyAll('all')}]);
  };

  const loadCampuses = () => {
    OneCampusFetchAtATime.start();
    Auth.fetch('/campuses', {
      params: {
        schoolIds: schoolId.toString(),
      },
      cancelToken: OneCampusFetchAtATime.token(),
    }).then(res => {
      OneCampusFetchAtATime.finish();
      let campuses = campusesFromResponseData(res.data);

      campuses = [
        {name: "All Campuses", campusId: campusKeyAll(schoolId)},
        ...campuses
      ];
      setCampuses(campuses);
      setCampusKey(initialCampus || campusKeyAll(schoolId));
      setInitialCampus(null);
    }).catch((err) => {
      if (!err.response) {
        console.warn(err);
        return;
      }
      if (err.response.status === 403) {
        handleLogout();
      }
    });
  };

  const openEvDrawer = (application) => {
    new Inspectlet().tag('EV Opened');
    userInfo.getMixpanelClient().trackEvDrawerOpened(application.appId);

    setActiveEvApplication(application);
    API.getDisbursementSchedule({
      params: {
        applicationId: application.appId
      },
      method: 'GET',
    }).then((resp) => {
      setDisbursementSchedule(resp.data)
    }).catch(error => {
      console.error('Error fetching disbursement schedule:', error);
    });
  };

  const closeEvDrawer = () => {
    setActiveEvApplication(null);
  };

  const cancelEvDrawer = (application) => {
    // if the user keep opening and closing the drawer really fast,
    // this callback will be called without having an application, and will the front-end will crash
    // this is probably due to animation delays
    // the following "if" prevents that
    if (application) {
      userInfo.getMixpanelClient().trackEvCanceled(application.appId);
    }
    new Inspectlet().tag('EV Cancelled');
    closeEvDrawer();
  };

  const openRefundDrawer = (application) => {
    userInfo.getMixpanelClient().trackRefundDrawerOpened(application.appId);
    new Inspectlet().tag('Refund Form Opened');
    setActiveRefundApplication(application);
  };

  const closeRefundDrawer = () => {
    setActiveRefundApplication(null);
  };

  const cancelRefundDrawer = (application) => {
    userInfo.getMixpanelClient().trackRefundCanceled(application.appId);
    new Inspectlet().tag('Refund Cancelled');
    closeRefundDrawer();
  };

  const refreshApplication = (applicationId) => {
    setCurrentRefreshingApp(applicationId);
    Auth.fetch('/', {
      params: {applicationId},
    }).then(res => {
      const application = res.data.content[0];
      setStudentApplications(replaceApplication(
        studentApplications, application));
      setCurrentRefreshingApp(null);
    }).catch((err) => {
      setCurrentRefreshingApp(null)
    });
  };

  const toggleApplicationExpanded = (application) => {
    userInfo.getMixpanelClient().trackAppCardToggled(application.appId, !application.expandedView);
    setStudentApplications(replaceApplication(
      studentApplications,
      {...application, expandedView: !application.expandedView},
    ));
  };

  const secondaryUserName = () => hasAltUserPermissions
      ? userInfo.getCurrentUsername()
      : null

  return (userMounted &&
    <MuiThemeProvider theme={theme}>
      <Toast
        timeout={undefined}
        displayToast={displayToastRef}
      />

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="main">
          <div>
            <Header homeLink={'/?campus=' + campusKey} secondaryUserName={secondaryUserName()}>
              <Button className="header-logout" data-testid="logout-button"
                      disableRipple={true}
                      focusVisibleClassName="header-logout--focus"
                      onClick={handleLogout.bind(this)}>Log Out</Button>
            </Header>
          </div>




      <div className="main-container">
        <div className="centered-container">

          <Container className="container" maxWidth="lg">

            <div className="page-header">

              {maybeDisplaySecondaryUser()}

              <div className="page-header__reports-container">

                {
                  schoolId !== null
                  && userInfo.canRefundForSchool(schoolId)
                    ? <div className="page-header__reports--button">
                      <a className="page-header__reports--button-title"
                         href={API.refundsReportURI({schoolId: schoolId})}
                         onClick={() => userInfo.getMixpanelClient().trackRefundsReportButtonClicked(schoolId)}>
                        Download Refund Report <GetApp className={"page-header__reports--button-title--arrow"}/>
                      </a>
                    </div>
                    : <a href="sorry mario" style={{visibility: 'hidden'}}>the princess is in another castle</a>
                }

                {
                  userInfo.canViewBasicInfoForAllAssignedSchools()
                    ? <div className="page-header__reports--button">
                      <a className="page-header__reports--button-title"
                         href={API.applicationReportURI({schoolId: schoolId})}
                         onClick={() => userInfo.getMixpanelClient().trackAppReportButtonClicked(schoolId)}>
                        Download Application Report <GetApp className={"page-header__reports--button-title--arrow"}/>
                      </a>
                    </div>
                    : <a href="sorry mario" style={{visibility: 'hidden'}}>the princess is in another castle</a>
                }

                {
                  userInfo.canDownloadDisbursementReportForAllAssignedSchools()
                    ? <div className="page-header__reports--button">
                      <a className="page-header__reports--button-title"
                         href={API.disbursementsReportURI({schoolId: schoolId})}
                         onClick={() => userInfo.getMixpanelClient().trackDisbReportButtonClicked(schoolId)}>
                        Download Disbursement Report <GetApp className={"page-header__reports--button-title--arrow"}/>
                      </a>
                    </div>
                    : <a href="sorry mario" style={{visibility: 'hidden'}}>the princess is in another castle</a>
                }

                {
                  userInfo.canViewBasicInfoForAllAssignedSchools()
                  ? <div className="page-header__reports--button">
                    <a className="page-header__reports--button-title"
                       href={API.summaryReportURI({schoolId: schoolId})}
                       onClick={() => userInfo.getMixpanelClient().trackSumReportButtonClicked(schoolId)}>
                      Download Summary Report <GetApp className={"page-header__reports--button-title--arrow"}/>
                    </a>
                  </div>
                  : <a href="sorry mario" style={{visibility: 'hidden'}}>the princess is in another castle</a>
                }

                {
                  userInfo.canDownloadRiskShareReports()
                    ? <RiskShareReportMenu availableReports={
                      (availableRiskShareReports || []).map(({month, approved}) => (
                        {
                          month,
                          approved,
                          url: API.riskShareReportURI({schoolIds: schoolId, month}),
                        })
                      )
                    }/>
                    : <a href="sorry mario" style={{visibility: 'hidden'}}>the princess is in another castle</a>
                }
              </div>
              <div className="page-header__filter-container">
                {
                  userInfo.canViewBasicInfoForAllAssignedSchools() &&
                  <>
                    <div className="page-header__search">
                      <SearchBox
                        onChangeValue={value => setSearchBoxValue(value)}
                        value={searchBoxValue}
                        statusList={statusList}
                        checkedStatuses={checkedStatuses}
                        setCheckedStatuses={setCheckedStatuses}
                        loading={loading}
                        onSearch={(search) => {
                          setSearch(search);
                          setDesiredPage(firstPageState());
                          setStatusList(checkedStatuses || []);

                          userInfo.getMixpanelClient().trackSearchSent(
                            search.type, search.triggeredByEnterKey);
                        }}

                        disabled={campusKey == null}
                        placeholder="Search by name, email, or app ID"
                      />

                    </div>

                    {
                      userInfo.canEvForAnySchool() &&
                      <FormControlLabel
                        className="page-header__ev-only-switch"
                        control={<EvOnlySwitch
                          inputProps={{'data-testid': 'ev-only-switch'}}
                          checked={showOnlyEvs}
                          disabled={loading}
                          onClick={toggleEvOnlyApplications}/>}
                        label="Only show EV's"
                        labelPlacement="start"
                      />
                    }
                    <div className="page-header__school-campus">
                      {maybeDisplaySchoolSelector()}

                      <CampusSelector data-testid="campus-selector"
                                      campuses={campuses}
                                      campusId={campusKey || ''}
                                      disabled={schoolId === 'all'}
                                      onChange={(campusId) => onChangeCampus(campusId)}/>
                    </div>
                  </>
                }
              </div>
            </div>

            {
              userInfo.canViewBasicInfoForAllAssignedSchools() &&
              <>
                <ColumnHeaderCard/>
                {
                  loading ?
                    <LoadingIndicator/>
                    :
                    studentApplications && studentApplications.length > 0 ?
                      studentApplications.map((student, index) => {
                        return (
                          // We have to be careful pass props with the same object identity whenever
                          // possible to avoid unnecessary ApplicationCard rendering here.
                          <ApplicationCard
                            toggleExpanded={toggleApplicationExpanded}
                            expanded={!!student.expandedView}
                            evNowClick={openEvDrawer}
                            key={student.appId}
                            application={student}
                            auth={Auth}
                            openRefundDrawer={
                              userInfo.isAdmin() || userInfo.canRefundForSchool(student.schoolId)
                                ? openRefundDrawer : null
                            }
                            evNowDisabled={student.appId === currentRefreshingApp}
                          />);
                      })
                      : <NoResults campusKey={campusKey} showOnlyEvs={showOnlyEvs}
                                   searchQuery={search.query} statusFilterApplied={!!statusList.length}/>
                }
                {(studentApplications &&
                  studentApplications.length > 0) ?
                  (<Paging first={currentPage.first}
                           last={currentPage.last}
                           currentPage={currentPage.pageNumber}
                           totalPages={currentPage.totalPages}
                           onClick={(pageNumber) => {
                             onPageClick(pageNumber);
                           }}
                  />) : ''}
              </>
            }

          </Container>

          {(new Date()).getFullYear() === 2023 && <div className="platform-fee-reminder">
            <img className="platform-fee-reminder_image" alt="icon-exclaim-svg" src={IconExclaimSvg}/>
            <div className="platform-fee-reminder_title"> PLATFORM FEE REMINDER </div>
            <div className="platform-fee-reminder_body">
              As a reminder, all outstanding Platform Fees are due by 12/31/2023. If you have questions about your school's status or how to pay your Platform Fees, please reach out to your Partnership Manager or email:
            </div>
            <a className="platform-fee-reminder_email" href="mailto:partnership@meritize.com">partnership@meritize.com</a>
          </div>}

        </div>
      </div>

          <Drawer
            variant="temporary"
            disableEscapeKeyDown={true}
            onClose={() => {
              cancelEvDrawer(activeEvApplication);
            }}
            anchor="right"
            open={!!activeEvApplication}>

            {activeEvApplication &&
            <div className="ev-drawer">
              <EnrollmentVerificationForm
                application={activeEvApplication}
                disbursementSchedule={disbursementSchedule}
                schoolId={activeEvApplication.schoolId}
                auth={Auth}
                data-testid="ev-form"
                onCancel={() => {
                  cancelEvDrawer(activeEvApplication);
                }}
                onEvSuccessful={(appId) => {
                  refreshApplication(appId);
                  closeEvDrawer();
                  new Inspectlet().tag('EV Submitted');
                }}
              />
            </div>}

          </Drawer>

          <Drawer
            variant="temporary"
            disableEscapeKeyDown={true}
            onClose={() => {
              cancelRefundDrawer(activeRefundApplication);
            }}
            anchor="right"
            open={!!activeRefundApplication}
            className="refund-drawer"
            PaperProps={{className: "wrapping-paper"}}
          >
            <Paper className="form-container">
              {activeRefundApplication &&
              <RefundForm application={activeRefundApplication} schoolId={schoolId}
                          onCancel={() => {
                            cancelRefundDrawer(activeRefundApplication);
                          }}
                          onSuccess={(appId) => {
                            refreshApplication(appId);
                            closeRefundDrawer();
                            new Inspectlet().tag('Refund Submitted');
                          }}
              />
              }
            </Paper>
          </Drawer>

        </div>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

export {replaceApplication, campusesFromResponseData};

export default withAuth(Main);
