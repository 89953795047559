import axios from 'axios';
import {API} from "./APIClient";

let TOKEN_EXPIRY_INTERVAL_MS_15_MINS = 900_000; //15 minutes

export default class AuthService {

  constructor(domain) {
    this.domain = domain;
    this.subscriberCallbacks = [];
  }

  startTokenExpiryInterval = () => {
    this.stopTokenExpiryInterval();
    this.tokenExpiryInterval = setInterval(this.checkTokenExpiry, TOKEN_EXPIRY_INTERVAL_MS_15_MINS);
  }

  checkTokenExpiry = () => {
    if (this.arePermissionsExpired(this.getPermissions())) {
      this.logout();
    }
  }

  stopTokenExpiryInterval = () => {
    clearInterval(this.tokenExpiryInterval);
  }

  login = (username, password) => {
    return this.fetch('/login', {
      withCredentials: true,
      method: 'POST',
      data: {
        username,
        password,
      },
    }).then(() => {
      API.mixpanelTrackLogin();
    }).then(() => {
      this.startTokenExpiryInterval();
      return this.fetch('/permissions/info');
    }).then(res => {
      this.setPermissions(res.data);
      return res;
    });
  };

  loggedIn = () => {
    let jsonPermissions = this.getPermissions();
    if (!jsonPermissions || typeof jsonPermissions !== "object" || !jsonPermissions.exp) {
      return false;
    }
    return !this.arePermissionsExpired(jsonPermissions);
  };

  arePermissionsExpired = (jsonPermissions) => {
    try {
       return jsonPermissions.exp < this.now().getTime();
    } catch (err){
      console.log(err);
      return true;
    }
  };

  now = () => { // this exists only for test mocking
    return new Date(Date.now());
  };

  setPermissions = (jsonPermissions, key = 'auth_token') => {
    localStorage.setItem(key, JSON.stringify(jsonPermissions));
  };

  getPermissions = () => {
    try {
      if(!!localStorage.getItem('auth_token_alt')) {
        return JSON.parse(localStorage.getItem('auth_token_alt'));
      }
      return JSON.parse(localStorage.getItem('auth_token'));
    } catch (err){
      return null;
    }
  };

  logout = () => {
    localStorage.removeItem('auth_token_alt');
    localStorage.removeItem('auth_token');
    this.stopTokenExpiryInterval();
    this.callLogoutCallbacks();
  };

  callLogoutCallbacks = () => {
    this.subscriberCallbacks.forEach(subCallback => {
      subCallback();
    })
  }

  addSubscriberCallback = (subscriberCb) => {
    this.subscriberCallbacks.push(subscriberCb);
  }

  fetch = (url, options) => {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    return axios({
      url: `${this.domain}${url}`,
      headers,
      withCredentials: true,
      ...options,
    }).then(this._checkStatus);
  };

  submit = (url, options) => {
    return this.fetch(url, {method: 'post', ...options});
  };

  _checkStatus = (response) => {
    if (response.status === 200) {
      return response;
    } else {
      let error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  };

  getFromPermissions(prop) {
    if (this.getPermissions() == null) {
      return null;
    }
    const permissions = this.getPermissions();
    if (this.arePermissionsExpired(permissions)) {
      return null;
    }
    return permissions[prop];
  }

  setAlternativeUser(userName, displayToast){
    return this.fetch('/permissions/secondaryUser', {
        params: {
          username: userName
        },
      }).then(res => {
        this.setPermissions(res.data, 'auth_token_alt')
      }).catch(err => {
        if(err?.response?.status === 404){
          return displayToast('This user does not exist, or is not assigned to any school.', 'error')
        }
        console.warn(err.response);
      });
  }

  removeAlternativeUser(){
    return this.fetch("/permissions/removeSecondaryUser").then((res) => {
      localStorage.removeItem('auth_token_alt');
    }).catch(err => {
      console.log(err);
    });
  }

}
