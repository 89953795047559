import React, {PureComponent, useEffect, useState} from 'react';
import Card from "@material-ui/core/Card";
import './css/ApplicationCard.css';
import '../css/ApplicationList.css';
import ApplicantSummary from "../../ApplicantSummary/ApplicantSummary";
import ProgramSummary from "../../ProgramSummary/ProgramSummary";
import LoanSummary from "./LoanSummary";
import DisbursementSummary from "../../DisbursementSummary/DisbursementSummary";
import ApplicationExpandable from "../../ApplicationExpandable/ApplicationExpandable";
import ApplicationStatus from "../../ApplicationStatus/ApplicationStatus";
import CTAButton from "../../CTAButton/CTAButton";
import UserInfo from "../../../util/UserInfo";
import {Auth} from "../../../util/withAuth";


const userInfo = new UserInfo(Auth);

export default class ApplicationCard extends PureComponent {
  // Using PureComponent avoids having to re-render all the applications when other things on the main page change.

  constructor(props) {
    super(props);
    this.state = {
      hoverExpander: false,
    }
    this.changeExpanderHover = (state) => {
      this.setState({hoverExpander: state})
    }
  }

  state = {
    hasDisbursementToStudent: false
  }

  render() {
    let {application, auth, expanded, toggleExpanded, evNowClick, openRefundDrawer, evNowDisabled} = this.props;

    auth.fetch('/school/' + application.schoolId, {
      params: {}
    }).then(res => {
      this.setState({hasDisbursementToStudent: res.data.disbursementToStudent})
    }).catch((err) => {
      if (!err.response) {
        console.warn(err);
      }
    });

    return (
      <Card variant="outlined"
            className={`application-summary__card card width_fill ${this.state.hoverExpander ? 'application-summary__card--hover' : ''}`}
            data-testid="app-card-full">
        <div className="application-summary list-card" data-testid="app-card">
          <div
            className="application-summary__applicant-summary app-section app-section--right-border list-card__applicant">
            <ApplicantSummary application={application}/></div>
          <div
            className="application-summary__program-summary app-section app-section--right-border list-card__program">
            <ProgramSummary application={application}/></div>
          <div className="application-summary__loan-summary app-section app-section--right-border list-card__loan">
            <LoanSummary application={application} disbursementToStudent={this.state.hasDisbursementToStudent}/></div>
          <div
            className="application-summary__application-status app-section app-section--right-border list-card__status">
            <ApplicationStatus
              statusCode={application.statusCode} application={application}/></div>
          {
            application.isEvNeeded && userInfo.canEvForSchool(application.schoolId) ?
              <div className="application-summary__disbursement-summary--ev-button list-card__disbursement">
                <CTAButton btnStyle="action"
                           data-testid='ev-now'
                           classAffix="application-summary__ev-now-button"
                           onClick={() => evNowClick(application)}
                           disabled={evNowDisabled}>Ev Now
                </CTAButton>
              </div>
              :
              <div className="application-summary__disbursement-summary list-card__disbursement">
                <DisbursementSummary disbursements={application.disbursements}/>
              </div>
          }
        </div>

        {application.view === 'extended' &&
          <ApplicationExpandable openRefundDrawer={openRefundDrawer} application={application} expanded={expanded}
                                 toggleExpanded={toggleExpanded} changeExpanderHover={this.changeExpanderHover}
                                 hasDisbursementToStudent={this.state.hasDisbursementToStudent}
          />
        }
      </Card>
    );
  }
}
