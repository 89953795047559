import React, {Component} from 'react';
import {Auth} from 'util/withAuth';
import CTAButton from "../../component/CTAButton/CTAButton";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import LoginHeader from "component/LoginHeader/LoginHeader";
import TextField from "@material-ui/core/TextField";
import validate from 'validate.js';
import './Login.scss';
import ExitSvg from '../../assets/images/close.svg';
import ExitHoverSvg from '../../assets/images/close_hover.svg';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SchoolSvg from "../../assets/images/School.svg";
import * as Sentry from "@sentry/browser";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      error: null,
      email: null,
      invalidEmailError: null,
      successfulPasswordChange: (this.props?.location?.state) ? this.props.location.state.successfulPasswordChange : false,
      expiredTokenPasswordChange: (this.props?.location?.state) ? this.props.location.state.expiredTokenPasswordChange : false,
      openForgotPassword: (this.props?.location?.state) ? this.props.location.state.expiredTokenPasswordChange : false,
    }
  }

  isCookieEnabled = () => {
    if (navigator.cookieEnabled) return true;
    document.cookie = "cookietest=1";
    let ret = document.cookie.indexOf("cookietest=") !== -1;
    document.cookie = "cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
    return !!ret;
  }

  refreshAppIfNeeded = () => {
    try {
      let shouldRefreshPageAfter = new Date()
      shouldRefreshPageAfter.setSeconds(shouldRefreshPageAfter.getSeconds() - 30)
      shouldRefreshPageAfter = shouldRefreshPageAfter.getTime()

      let maybeLastTimeWebsiteWasRefreshed = localStorage.getItem("lastTimeWebsiteWasRefreshed")
      maybeLastTimeWebsiteWasRefreshed = maybeLastTimeWebsiteWasRefreshed
        ? parseInt(maybeLastTimeWebsiteWasRefreshed)
        : null

      if (!maybeLastTimeWebsiteWasRefreshed
        || maybeLastTimeWebsiteWasRefreshed < shouldRefreshPageAfter) {
        localStorage.setItem("lastTimeWebsiteWasRefreshed", Date.now())
        window.location.reload();
      }
    } catch (error) {
      console.error("Error trying to save cookie or getting item from localStorage. User probably disabled cookies and/or localStorage on browser");
      console.error(error);
      Sentry.withScope(scope => {
        scope.setExtra("function", "refreshAppIfNeeded")
        scope.setExtra("message", "Error trying to save cookie or getting item from localStorage. User probably disabled cookies and/or localStorage on browser")
        Sentry.captureException(error)
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.isCookieEnabled()) {
      this.setState({error: 'cookies_disabled_error'});
      Sentry.withScope(scope => {
        scope.setExtra("function", "handleSubmit")
        scope.setExtra("username", this.state.username)
        Sentry.captureMessage("Error trying to save cookie or using localStorage.")
      });
      return;
    }
    Auth.login(this.state.username, this.state.password)
      .then(res => {
        this.props.history.replace('/');
        this.props.onLogin();
      })
      .catch((err) => {
        console.log(err);
        if (!!err.response && err.response.status === 401) {
          try {
            this.setState({error: err.response.data.error});
          } catch (e) {
            this.setState({error: 'unexpected_error'});
          }
        } else {
          this.setState({error: 'unexpected_error'});
        }
      })
  }

  componentDidMount() {
    this.refreshAppIfNeeded();

    if (Auth.loggedIn()) {
      this.props.history.replace('/');
    }
  }

  messageLookup = {
    'bad_credentials': "Invalid username and/or password.",
    'unexpected_error': "Unexpected error, please contact Meritize support.",
    'account_locked': "We were unable to log you in at this time.  Please contact your Meritize Partnerships associate for assistance.",
    'cookies_disabled_error': "Cookies are not enabled on your browser. Please enable cookies in your browser preferences to continue.",
  };

  handleOpenForgotPassword = (e) => {
    e.preventDefault();
    this.setState({
      openForgotPassword: true
    });
  };

  handleCloseForgotPassword = () => {
    this.setState({
      openForgotPassword: false,
    });
  };

  submitForgotPassword = () => {
    if (!this.looksLikeEmail(this.state.email)) {
      this.setState({invalidEmailError: "Valid email needed"})
      return;
    }
    this.setState({invalidEmailError: null});
    Auth.submit('/reset-password-start', {
      params: {
        email: this.state.email
      }
    })
      .then(res => {
      }).catch(err => console.log(err));
    this.handleCloseForgotPassword();
  }

  forgotPasswordOnChange = (event) => {
    this.setState({email: event.target.value});
  }

  looksLikeEmail = (text) => {
    const emailConstraints = {
      searchVal: {
        email: true,
        presence: true
      },
    };
    return !validate({searchVal: text}, emailConstraints);
  };

  render() {

    return (
      <div className="login-page">
        <LoginHeader/>
        <div className="login">
          {
            (this.state.error && this.state.error === 'account_locked') ?
              <div className="login__major-error" data-testid="account-locked-message">
                <h2>Sorry:</h2>
                {this.messageLookup['account_locked']}
              </div>
              : null
          }
          <h1 className="login__header">Welcome to the School Portal</h1>
          <span className="login__sub-header">Enter your email and password to login!</span><br/>
          <div className="login__error" data-testid="login-error">
            {
              (this.state.error && this.state.error !== 'account_locked') ?
                this.messageLookup[this.state.error] : null
            }
          </div>
          <form onSubmit={this.handleSubmit}>
            <label className="login__label" htmlFor="login-username">Username</label>
            <TextField
              autoFocus
              className="login__input"
              label="Email"
              name="username"
              type="text"
              id="login-username"
              onChange={this.handleChange}
              inputProps={{ "data-testid": "login-username" }}
            />
            <br/>
            <label className="login__label" htmlFor="login-password">Password</label>
            <TextField
              className="login__input"
              label="Password"
              name="password"
              type="password"
              id="login-password"
              onChange={this.handleChange}
              inputProps={{ "data-testid": "login-password" }}
            />

            <p className="login__forgot-password-wrapper">
              <a href="#" onClick={this.handleOpenForgotPassword}
                 className="login__forgot-password">
                Forgot password?</a>
            </p>
            <Dialog className="login__forgot-password--container" open={this.state.openForgotPassword || false}
                    onClose={this.handleCloseForgotPassword}>
              <img className="login__forgot-password--exit-img" alt="exit-img"
                   src={ExitSvg}
                   onMouseOver={e => e.currentTarget.src = ExitHoverSvg}
                   onMouseOut={e => e.currentTarget.src = ExitSvg}
                   onClick={this.handleCloseForgotPassword}/>
              <DialogTitle
                className="login__forgot-password--title">{this.state.expiredTokenPasswordChange ? <>Sorry, this
                link has expired</> : <>Forgot password?</>}</DialogTitle>
              <DialogContent>
                <DialogContentText className="login__forgot-password--text">
                  {this.state.expiredTokenPasswordChange
                    ? <>The link you are trying to access is no longer available. Please enter your email below to
                      request a new password link.</>
                    : <>Enter the email address you use when you log in and we’ll send you instructions to reset
                      your password.</>}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="forgot-email"
                  label="Email"
                  type="email"
                  onChange={this.forgotPasswordOnChange}
                  fullWidth
                  helperText={this.state.invalidEmailError}
                  error={!!this.state.invalidEmailError}
                  inputProps={{ "data-testid": "forgot-email" }}
                />
              </DialogContent>
              <DialogActions className="login__forgot-password--button-wrapper">
                <CTAButton onClick={this.submitForgotPassword} color="primary"
                           classAffix={"cta-button--submit__rounded-corners"}
                           btnStyle="submit"
                           data-testid="request-reset-password-email">
                  {this.state.expiredTokenPasswordChange
                    ? <>RE-SEND LINK</> : <>RESET MY PASSWORD</>}
                </CTAButton>
              </DialogActions>
            </Dialog>

            <br/>
            <input
              className="login__submit"
              value="LOG IN"
              type="submit"
              data-testid="login-submit"
            />
          </form>
        </div>
        <div className="login__background-image">
          <img className="login__school-image" alt="school-img" src={SchoolSvg}/>
        </div>
        <Snackbar open={this.state.successfulPasswordChange} autoHideDuration={6000} onClose={() => {
          this.handlePasswordChangeClose()
        }}>
          <Alert severity="success">
            Your password has been reset!
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                this.handlePasswordChangeClose()
              }}
              data-testid="passwordChange-close-button"
            >
              <CloseIcon fontSize="inherit"/>
            </IconButton>
          </Alert>
        </Snackbar>
      </div>
    );
  }

  handlePasswordChangeClose() {
    this.setState({successfulPasswordChange: false});
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      }
    )
  }
}

export default Login;
