import Environment from 'util/Environment';
import * as axios from "axios";

export class APIClient {
  constructor ({fetcher, domain}) {
    this.fetcher = fetcher;
    this.domain = domain.replace(/\/$/, '');
  }

  doRequest(path, {cancellerReturn, ...params}) {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    if (cancellerReturn) {
      const cancelToken = this.fetcher.CancelToken.source();
      cancellerReturn(cancelToken.cancel);
    }

    return this.fetcher({
      url: this.domain + path,
      headers,
      withCredentials: true,
      ...params,
    });
  }

  riskShareReportURI({schoolIds, month}) {
    return `${this.domain}/reports/risk-share?schoolIds=${schoolIds}&month=${month}`;
  }

  disbursementsReportURI({schoolId}) {
    return `${this.domain}/reports/disbursements?schoolId=${schoolId}`;
  }

  applicationReportURI({schoolId}) {
    return `${this.domain}/reports/application?schoolIds=${schoolId}`;
  }

  refundsReportURI({schoolId}) {
    return `${this.domain}/reports/refunds?schoolIds=${schoolId}`;
  }

  summaryReportURI({schoolId}) {
    return `${this.domain}/reports/summary?schoolIds=${schoolId}`;
  }

  fetchAvailableReportsList(schoolId, opts) {
    return this.doRequest('/reports/risk-share/list?schoolIds='+schoolId, {method: 'GET', ...opts})
  }

  fetchApplications(opts) {
    return this.doRequest('/', {method: 'GET', ...opts});
  }

  getPaymentDetails(opts) {
    return this.doRequest('/refund/payment-details', {method: 'GET', ...opts});
  }

  getDisbursementSchedule(opts) {
    return this.doRequest('/disbursement-schedule', {method: 'GET', ...opts});
  }

  calculateRefund(opts) {
    return this.doRequest('/refund/calculate', {method: 'GET', ...opts});
  }

  submitRefund(opts) {
    return this.doRequest('/refund/submit', {method: 'POST', ...opts});
  }

  mixpanelTrack(event, properties, opts) {
    this.doRequest('/mixpanel', {method: 'POST', data: {event, properties}, ...opts})
      .catch((err) => {
        console.log(err)
      });
  }

  mixpanelTrackLogin() {
    this.doRequest('/mixpanel-login', {method: 'POST'})
      .catch((err) => {
        console.log(err)
      });
  }

  resetPasswordVerifyToken(opts) {
    return this.doRequest('/reset-password-verify-token', {method: 'POST', ...opts});
  }

  marketingMaterials(){
    return this.doRequest('/links/marketing', {method: 'GET'})
  }

  getSchool(schoolId){
    return this.doRequest('/school/' + schoolId,{method: 'GET'})
  }
}

export const API = new APIClient({
  fetcher: axios,
  domain: Environment.apiUrl(),
})
