const strToBool = (s) => {
  return /^(true|yes|on|1)$/.test((s || '').toLowerCase());
}

export class EnvironmentConfig {
  constructor(window, process_env_func) {
    this.window = window;
    // We use a function here because there's some magic behind process.env that prohibits just saving
    // the value of `process`. We need to access `env` lazily so we can mock it in some tests.
    this.process_env_func = process_env_func;
  }

  envConf(name, reactAppName) {
    return this.window.environmentConfig ?
      this.window.environmentConfig[name] :
      this.process_env_func()[reactAppName];
  }

  isProdBuild() {
    return !!this.window.environmentConfig;
  }

  announcementMessage() {
    const msg = this.envConf('announcementMessage', 'REACT_APP_ANNOUNCEMENT_MESSAGE');
    return msg && msg !== 'undefined' ? msg : null;
  }
  redirectToMaintenance() { return strToBool(this.envConf('redirectToMaintenance', 'REACT_APP_REDIRECT_TO_MAINTENANCE')); }
  apiUrl() {
    const apiUrl = this.envConf('API_URL', 'REACT_APP_API_URL');
    if (!apiUrl) {
      throw new Error("API URL must be configured, either through window.environmentConfig or REACT_APP_API_URL.");
    }
    return apiUrl;
  }

  requestPageSize()   {
    if (window.SUNDOWNER_OVERRIDE_PAGE_SIZE !== undefined) {
      return window.SUNDOWNER_OVERRIDE_PAGE_SIZE;
    } else {
      return this.envConf('REQUEST_PAGE_SIZE', 'REACT_APP_REQUEST_PAGE_SIZE');
    }
  }
  sentryDsn()         { return this.envConf('SENTRY_DSN',         'REACT_APP_SENTRY_DSN');         }
  sentryEnvironment() { return this.envConf('SENTRY_ENVIRONMENT', 'REACT_APP_SENTRY_ENVIRONMENT'); }

  featureFlagEnabled(flagName) {
    const enabledFlags = this.envConf('FEATURE_FLAGS', 'REACT_APP_FEATURE_FLAGS');
    if (enabledFlags === undefined) { return false; }
    return enabledFlags.split(/,/).indexOf(flagName) >= 0;
  }

  schoolFourDisbursementsEnabled(schoolId) {
    const excludedSchoolIds = this.envConf('FOUR_DISBURSEMENTS_EXCLUDED_SCHOOLS', 'REACT_APP_FOUR_DISBURSEMENTS_EXCLUDED_SCHOOLS');
    if (excludedSchoolIds === undefined || !schoolId) { return true; }
    return excludedSchoolIds.split(/,/).indexOf(schoolId.toString()) < 0;
  }

  announcementExpired() {
    let announceExpiryDateStr = this.envConf("announcementMessageExpiryDate", 'REACT_APP_ANNOUNCEMENT_EXPIRY_DATE');
    if(announceExpiryDateStr){
      let currDate = new Date();
      let expiration = new Date(announceExpiryDateStr);
      return (currDate > expiration);
    }
    return false;
  }
}

let Environment = new EnvironmentConfig(window, () => process.env);

export default Environment;
