import React, {useEffect, useState} from 'react';
import {KeyboardDatePicker} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import CTAButton from '../CTAButton/CTAButton';
import Box from '@material-ui/core/Box';
import {addDays, format, isAfter, isEqual, isFriday, isValid, isSameDay} from 'date-fns';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import './css/EnrollmentVerificationForm.css';
import {produce} from 'immer';
import {formatCampusDisplayNameFromApplication} from '../../util/format';
import {IconTooltip} from '../IconTooltip/IconTooltip';
import styled from '@material-ui/core/styles/styled';
import Environment from '../../util/Environment';
import UserInfo from '../../util/UserInfo';
import {Auth} from '../../util/withAuth';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, NativeSelect} from "@material-ui/core";
import Big from "big.js";
import {range, sum} from 'ramda';
import {formatDollarAmount} from "../../util/format";

// import "big.js";

/*
* Ensure Date object matches string value regardless of timezone
*
* Used for Date strings where time is not set, to avoid incorrect conversion into specific timezones
* eg "2020-03-08" converts to "2020-03-08 00:00:00 CST" instead of "2020-03-07 18:00:00 CST"
* */

const userInfo = new UserInfo(Auth);

export function getDateFromStringIgnoreTimezone(dateString) {
  if (!dateString) {
    return null;
  }
  let date = new Date(dateString);
  date.setUTCMinutes(date.getTimezoneOffset());
  return date;
}

function getNextFriday(date = new Date()) {
  const dateCopy = new Date(date.getTime());
  return new Date(
    dateCopy.setDate(
      dateCopy.getDate() + ((7 - dateCopy.getDay() + 5) % 7 || 7),
    ),
  );
}

function getFridayThisWeek(date = new Date()) {
  const dateCopy = new Date(date.getTime());
  return new Date(
    dateCopy.setDate(
      dateCopy.getDate() + ((7 - dateCopy.getDay() + 5) % 7),
    ),
  );
}

export function disableAllExceptFridaysAfterThisWeek(date, startDate = new Date(), delayedDays = 0) {
  let firstValidDate = addDays(startDate, Math.max(7, delayedDays));
  firstValidDate = new Date(Math.max(firstValidDate, getFridayThisWeek()));
  let enabled = isFriday(date) && (isAfter(date, firstValidDate) || isEqual(date, firstValidDate)) && !isBlackoutDate(date);
  return !enabled
}

function isBlackoutDate(date) {
  const currentYear = date.getFullYear()

  let fridayOfJuly4th = getFridayOfJuly4th(currentYear)
  let blackFriday = getBlackFriday(currentYear)
  let fridayOfChristmas = getFridayOfChristmas(currentYear)

  if(isSameDay(date, fridayOfJuly4th)) {
    return true
  }
  if(isSameDay(date, blackFriday)) {
    return true
  }
  if (isSameDay(date, fridayOfChristmas)) {
    return true
  }
  if(fridayOfChristmas.getDate() === 24 && isSameDay(date, new Date(currentYear, 11, 31))) {
    return true
  }
  if(date.getMonth() === 0 && date.getDate() === 1 && date.getDay() === 5) {
    return true
  }
}

function getFridayOfJuly4th(year) {
  let july4th = new Date(year,6,4);
  let july4thDayOfWeek = july4th.getDay();
  let july4thDifferenceFromFriday = 5 - july4thDayOfWeek;
  return new Date(july4th.getFullYear(), 6,july4th.getDate() + july4thDifferenceFromFriday)
}

function getBlackFriday(year) {
  let november1st = new Date(year, 10, 1)
  let dayOf1st = november1st.getDay()
  let distanceToThursday = ((4 - dayOf1st) + 7) % 7
  let firstThursday = new Date(year, 10, november1st.getDate() + distanceToThursday)
  return new Date(year, 10, firstThursday.getDate() + 22) // 22 is 3 weeks (21 days) plus one to get the 4th friday
}

function getFridayOfChristmas(year) {
  let christmas = new Date(year, 11, 25)
  let christmasDayOfWeek = christmas.getDay();
  let christmasDifferenceFromFriday = 5 - christmasDayOfWeek
  return new Date(year, 11,christmas.getDate() + christmasDifferenceFromFriday)
}

function formatDate(date) {
  if (date !== null && isValid(date)) {
    return format(date, 'yyyy-MM-dd')
  }
  return null;
}

const MyInputLabel = styled(InputLabel)({
  transform: 'translate(0, 1.5px)',
  fontSize: '0.75rem',
});


export default function EnrollmentVerificationForm(props) {
  let [minDates, setMinDates] = useState([]);
  let [minDateMessages, setMinDateMessages] = useState([]);
  let {application, auth, onCancel, onEvSuccessful, disbursementSchedule, schoolId} = props;
  let [hasDisbursementToStudent, setHasDisbursementToStudent] = useState(false)

  function checkIfDisbursementToStudent() {
    auth.fetch('/school/' + schoolId, {
      params: {}
    }).then(res => {
      setHasDisbursementToStudent(res.data.disbursementToStudent)
    }).catch((err) => {
      if (!err.response) {
        console.warn(err);
      }
    });
  }

  const errorStrings = {
    'start-date-missing': 'Start Date is required',
    'start-date-too-far-out': 'Start date is too far out from application date. Please ensure your student’s start date is within 150 days from their application date.',
    'grad-date-missing': 'Grad Date is required',
    'grad-date-before-start-date': 'Graduation date must be after the start date',
    'confirmed-loan-amount-missing': 'Confirmed Loan Amount is required',
    'disbursement-amount-missing': 'Disbursement Amount is required',
    'disbursement-date-missing': 'Disbursement Date is required',
    'disbursement-to-student-missing': 'Disbursement to Student cannot be blank',
    'confirmed-loan-amount-zero': 'Confirmed Loan Amount must be greater than $0',
    'confirmed-loan-amount-greater-than-requested': 'Confirmed Loan Amount cannot be greater than Requested Loan Amount',
    'disbursement-date-not-friday': 'Disbursement Date must be on a Friday',
    'disbursement-date-too-soon': 'The disbursement date cannot be the same week you are verifying enrollment or before',
    'disbursement-date-holiday': 'Disbursement Date cannot fall on a holiday week',
    'disbursement-amounts-plus-amount-to-student-do-not-equal-loan-amount': 'Disbursement amounts and Disbursement to student must total to be the Confirmed Loan Amount',
    'disbursement-amounts-do-not-equal-loan-amount': 'Disbursement amounts must total to be the Confirmed Loan Amount',
    'disbursement-to-student-negative': 'Disbursement To Student must be greater than or equal to $0',
    'disbursement-amount-negative': 'Disbursement Amount must be greater than or equal to $0',
    'financial-aid-negative': 'Financial aid amount must be greater than or equal to $0',
    'disbursement-amount-too-low': 'Disbursement amount cannot be lower than $1000.',
    'disbursement-2-date-too-soon': 'Disbursement 2 date is too soon, select a disbursement date that is X months from the previous disbursement date.',
    'disbursement-3-date-too-soon': 'Disbursement 3 date is too soon, select a disbursement date that is X months from the previous disbursement date.',
    'disbursement-4-date-too-soon': 'Disbursement 4 date is too soon, select a disbursement date that is X months from the previous disbursement date.',
    'financial-aid-and-confirmed-loan-amount-exceeds-total-cost': `Financial aid + Confirmed Loan Amount cannot be greater than the Cost of Attendance for this program (Current Total Cost of Attendance is: %param%)`,
  };

  useEffect(() => {
    checkIfDisbursementToStudent();
  })

  let emptyDisbursement = () => ({amount: undefined, date: null});


  const [formInputs, handleFormInputs] = useState({
    startDate: getDateFromStringIgnoreTimezone(application.startDate),
    gradDate: (!!application.isProgramCpl) ? calculateCplGradDate(getDateFromStringIgnoreTimezone(application.startDate)) : getDateFromStringIgnoreTimezone(application.gradDate), //TODO: switch to true
    disbursements: [emptyDisbursement()],
  });

  const [validDates, setValidDates] = useState(false)
  const [errorMessages, handleErrorMessages] = useState(null);
  const [numDisbursements, handleNumDisbursements] = useState(1);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const submitEnabled = !!formInputs.startDate
    && !!formInputs.gradDate
    && !!formInputs.confirmedLoanAmount
    && (hasDisbursementToStudent ? !!formInputs.disbursementToStudent : true)
    && formInputs.disbursements.every(disb => !!disb.amount && !!disb.date)
    && validDates
    && !submitting
    && !!formInputs.enrollmentStatus


  function areValidDates(disbursements, minDates) {
    if (!disbursements.every(disb => !!disb.date)) {
      return false;
    }
    for (let i = 0; i < disbursements.length; i++) {
      if (!minDates[i]) {
        return false;
      }
      if (disbursements[i].date < minDates[i]) {
        return false;
      }
    }
    return true;
  }

  function setNumDisbursements(e) {
    const newNumDisbursements = parseInt(e.currentTarget.value, 10);
    handleNumDisbursements(newNumDisbursements);

    handleFormInputs(produce(formInputs, draftFormInputs => {
      if (draftFormInputs.disbursements.length > newNumDisbursements) {
        draftFormInputs.disbursements = draftFormInputs.disbursements.slice(0, newNumDisbursements);
      } else {
        while (draftFormInputs.disbursements.length < newNumDisbursements) {
          draftFormInputs.disbursements.push(emptyDisbursement());
        }
      }
      populateDisbursements(newNumDisbursements, draftFormInputs)
      setValidDates(areValidDates(draftFormInputs.disbursements, minDates));
    }));
  }

  function populateDisbursements(numDisbursements, draftFormInputs) {
    if (!!!draftFormInputs.confirmedLoanAmount) {
      for (let i = 0; i < numDisbursements; i++) {
        draftFormInputs.disbursements[i].amount = undefined
      }
      return
    } else if (!!!application.isProgramCpl) {
      return;
    }

    const loanAmount = new Big(draftFormInputs.confirmedLoanAmount).round(2, Big.roundHalfUp)

    calculateDisbursements(loanAmount, numDisbursements)
      .map(displayCentsOnlyIfNeeded)
      .forEach((disbursement, i) => draftFormInputs.disbursements[i].amount = disbursement)
  }


  function calculateDisbursements(loanAmount, numDisbursements) {
    validateArgsForCalculateDisbursement(loanAmount, numDisbursements)

    if (numDisbursements === 1)
      return [loanAmount]

    const firstDisbursements = calculateFirstDisbursements(loanAmount, numDisbursements)
    const sumFirstDirbursements = firstDisbursements.reduce((total, curr) => total.plus(curr), Big(0))

    const lastDisbursement = loanAmount.minus(sumFirstDirbursements).round(2, Big.roundHalfUp) // the last disbursement is always the difference (to make all the disbursements line up with the confirmed loan amount)

    return [...firstDisbursements, lastDisbursement]
  }

  function calculateFirstDisbursements(loanAmount, numDisbursements) {
    return range(1, numDisbursements)
      .map(disbursementNum => calculateDisbursementPercentage(numDisbursements, disbursementNum))
      .map(percentage => percentage.times(loanAmount))
      .map(toRound => toRound.round(2, Big.roundHalfUp))
  }

  function calculateDisbursementPercentage(numDisbursements, disbursementNum) {
    validateArgsForCalculateDisbursementPercentages(numDisbursements, disbursementNum)

    // the multiplier is one less then the number of disbursements because the last disbursement is the remainder
    let percentages
    if (numDisbursements === 2)
      percentages = [0.5];
    else if (numDisbursements === 3)
      percentages = [0.35, 0.35]
    else if (numDisbursements === 4)
      percentages = [0.3, 0.3, 0.3]
    else {
      throw new `unsupported number of disbursements: ${numDisbursements}`
    }

    return new Big(percentages[disbursementNum - 1])
  }

  function displayCentsOnlyIfNeeded(moneyAmountBig) {
    if (moneyAmountBig.mod(1).eq(0))
      return moneyAmountBig.toString()

    return moneyAmountBig.toFixed(2)
  }

  function validateArgsForCalculateDisbursement(loanAmount, numDisbursements) {

    if (!loanAmount || !(loanAmount instanceof Big))
      throw `invalid loanAmount: ${loanAmount}`

    if (!numDisbursements || typeof numDisbursements !== 'number')
      throw `invalid number of disbursements: ${numDisbursements}`
  }

  function validateArgsForCalculateDisbursementPercentages(numDisbursements, disbursementNum) {
    if (!disbursementNum || typeof disbursementNum !== 'number')
      throw `invalid disbursement number: ${disbursementNum}`

    if (disbursementNum === numDisbursements) {
      throw `this function is not able to calculate the last disbursement percentage. last disbursement percentage should not be a percentage, but the remainder (totalAmount - sum of previous disbursements). <numDisbursements = ${numDisbursements}, disbursementNum = ${disbursementNum}`
    }

    if (disbursementNum >= numDisbursements) {
      throw `cannot get percentage for disbursementNum ${disbursementNum} since it is higher than numDisbursements ${numDisbursements}`
    }

    if (disbursementNum < 1)
      throw `disbursementNum should be bigger then zero. it was ${disbursementNum} instead`
  }


  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setSubmitting(false)
  };

  const handleOkConfirmDialog = () => {
    setOpenConfirmDialog(false);
    submitForm();
  };

  function validateAndSubmitForm() {
    setSubmitting(true)
    const gradDateTime = formInputs.gradDate.getTime()
    let maxDisbursementDateTime = formInputs.disbursements[0].date.getTime()

    for (let i = 1; i < numDisbursements; i++) {
      maxDisbursementDateTime = Math.max(maxDisbursementDateTime, formInputs.disbursements[i].date.getTime())
    }

    if (maxDisbursementDateTime > gradDateTime) {
      setOpenConfirmDialog(true)
    } else {
      submitForm()
    }
  }

  function submitForm() {
    userInfo.getMixpanelClient().trackEvSubmitted(application.appId);
    auth.submit('/submit-enrollment-verification',
      {
        data: {
          applicationId: application.appId,
          startDate: formatDate(formInputs.startDate),
          gradDate: formatDate(formInputs.gradDate),
          confirmedLoanAmount: formInputs.confirmedLoanAmount,
          financialAid: formInputs.financialAid,
          enrollmentStatus: formInputs.enrollmentStatus,
          disbursementToStudent: formInputs.disbursementToStudent != null ? formInputs.disbursementToStudent : "0",
          disbursements: formInputs.disbursements.map((d) => ({
            amount: d.amount,
            date: formatDate(d.date),
          })),
          numberOfDisbursements: numDisbursements,
        }
      }).then(() => {
        setSubmitting(false)

        onEvSuccessful(application.appId);
    }).catch(error => {
      if (!!error && !!error.response && error.response.status === 422) {
        handleErrorMessages(parseErrors(error.response.data.errors))
      } else {
        handleErrorMessages(['Server error, please contact support.']);
      }
      setSubmitting(false)
    })
  }

  function parseErrors(errorObjects) {
    let errorMessages = [];

    errorObjects.forEach((errorObject) => {
      let errorString = errorStrings[errorObject.code]

      if (errorObject.code === 'disbursement-amounts-plus-amount-to-student-do-not-equal-loan-amount') {
        hasDisbursementToStudent ?
          errorMessages.push(errorString || 'Bad input, please check form values and try again.') :
          errorMessages.push(errorStrings['disbursement-amounts-do-not-equal-loan-amount'] || 'Bad input, please check form values and try again.')
      }
      else if (errorObject.code === 'financial-aid-and-confirmed-loan-amount-exceeds-total-cost') {
        errorMessages.push(errorString.replace('%param%', formatDollarAmount(application.loanBreakdown.totalCostOfAttendance)))
      } else {
        errorMessages.push(errorString || 'Bad input, please check form values and try again.');
      }
    })

    return errorMessages;
  }

  function fillStartDate(draftFormInputs, startDate) {
    draftFormInputs.startDate = startDate;
    if (isValidDate(startDate)) {
      fillDisbursementDatesFromStartDate(draftFormInputs, 0, firstValidDisbDate(startDate, draftFormInputs));
      if (!!application.isProgramCpl) {
        draftFormInputs.gradDate = calculateCplGradDate(getDateFromStringIgnoreTimezone(startDate));
      }
    }
  }

  function isValidDate(date) {
    return !!date && date instanceof Date && !isNaN(date)
  }

  function fillDisbursementDates(draftFormInputs, disbIndex, disbursementDate) {
    draftFormInputs.disbursements[disbIndex].date = disbursementDate;
    if (isValidDate(disbursementDate)) {
      if (disbursementSchedule && disbursementSchedule.length >= 3) {
        autoPopulateFollowingDisbursementDates(draftFormInputs, disbIndex);
        let tempMinDates = setCorrectMinDates(draftFormInputs);
        setMinDates(tempMinDates);
        setValidDates(areValidDates(draftFormInputs.disbursements, tempMinDates));
      } else {
        setValidDates(true);
      }
    }
  }

  function fillDisbursementDatesFromStartDate(draftFormInputs, disbIndex, disbursementDate) {
    let newDate = disbursementDate;
    if(!!newDate) {
      while(isBlackoutDate(newDate)) {
        newDate = addDays(newDate, 7)
      }
    }
    draftFormInputs.disbursements[disbIndex].date = newDate;
    if (isValidDate(newDate)) {
      if (disbursementSchedule && disbursementSchedule.length >= 3) {
        autoPopulateFollowingDisbursementDates(draftFormInputs, disbIndex);
        let tempMinDates = setCorrectMinDates(draftFormInputs);
        setMinDates(tempMinDates);
        setValidDates(areValidDates(draftFormInputs.disbursements, tempMinDates));
      } else {
        setValidDates(true);
      }
    }
  }

  function autoPopulateFollowingDisbursementDates(draftFormInputs, disbIndex) {
    let newDate = new Date(draftFormInputs.disbursements[disbIndex].date);
    for (var i = disbIndex + 1; i < numDisbursements; i++) {
      newDate.setMonth(newDate.getMonth() + disbursementSchedule[i - 1].months);
      newDate = getFridayThisWeek(newDate);
      while(isBlackoutDate(newDate)) {
        newDate = addDays(newDate, 7)
      }
      draftFormInputs.disbursements[i].date = new Date(newDate);
    }
  }

  function firstValidDisbDate(startDate) {
    let today = new Date().setHours(0, 0, 0, 0)
    if (application.delayedDisbursementDays === 0) {
      return getNextFriday(addDays(today, 7))
    }
    return new Date(Math.max(getNextFriday(addDays(startDate, application.delayedDisbursementDays)), getNextFriday(addDays(today, 7))));
  }

  function setCorrectMinDates(draftFormInputs) {
    let tempMinDates = [];
    let startMinDate = firstValidDisbDate(draftFormInputs.startDate, draftFormInputs)

    if (isValidDate(startMinDate)) {
      tempMinDates[0] = startMinDate;
      minDateMessages[0] = `Disbursement date cannot be before ${format(startMinDate, 'MM/dd/yyyy')} ${(application.delayedDisbursementDays === 0) ? '' : 'because of a delayed disbursement constraint'}`;

      for (let i = 1; i < numDisbursements; i++) {
        let newDate = new Date(draftFormInputs.disbursements[i - 1].date);
        if (!newDate) {
          newDate = new Date(tempMinDates[i - 1]);
        }
        newDate.setMonth(newDate.getMonth() + disbursementSchedule[i - 1].months);

        newDate = getFridayThisWeek(newDate);
        tempMinDates[i] = newDate;
        minDateMessages[i] = `Disbursement has a date that is too soon. Select a date that is ${disbursementSchedule[i - 1].months} months from the previous disbursement date.`;
      }
    }
    return tempMinDates;
  }

  function calculateCplGradDate(date) {
    let futureDate = new Date(date);
    futureDate.setMonth(date.getMonth() + 18);
    return futureDate;
  }

  return (
    <div className="ev-form" data-testid="ev-form">
      <div className="ev-form__fields-section">
        <h4 className="ev-form__title">Enrollment Verification</h4>

        <h4 className="ev-form__section-header">Verify</h4>

        <div className="ev-form__row">
          <TextField
            className="ev-form__name ev-form__input"
            disabled={true}
            id="ev-form-name"
            label="Name"
            value={application.firstName + application.middleInitial + ' ' + application.lastName}
            InputLabelProps={{shrink: true}}
            InputProps={{'classes': {'input': 'inspectlet-sensitive'}}}
          />
          <TextField
            className="ev-form__dob ev-form__input"
            disabled={true}
            label="Date of Birth"
            value={application.dob}
            InputLabelProps={{shrink: true}}
            style={{width: '6rem'}}
            InputProps={{'classes': {'input': 'inspectlet-sensitive'}}}
          />
        </div>

        <div className="ev-form__row">
          <TextField
            className="ev-form__email ev-form__input"
            disabled={true}
            label="Email"
            value={application.email}
            InputLabelProps={{shrink: true}}
            style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
            InputProps={{'classes': {'input': 'inspectlet-sensitive'}}}
          />

          <FormControl className="ev-form__total-cost-of-attendance ev-form__input">
            <MyInputLabel disabled={true}>Cost of Attendance
              <IconTooltip
                data-testid="total-cost-of-attendance-tooltip-icon"
                placement="bottom-start"
                title="Cost of Attendance was provided by your school during onboarding"
              />
            </MyInputLabel>
            <Input
              type="text"
              disabled={true}
              inputProps={{'data-testid': 'total-cost-of-attendance'}}
              value={formatDollarAmount(application.loanBreakdown.totalCostOfAttendance)}
              style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
            />
          </FormControl>


        </div>
        <div className="ev-form__row">
          <TextField className="ev-form__campus ev-form__input"
                     disabled={true}
                     label="Campus"
                     value={formatCampusDisplayNameFromApplication(application)}
                     InputLabelProps={{shrink: true}}
                     inputProps={{'data-testid': 'campus'}}
          />

          <Tooltip title={application.programName}>
            <TextField className="ev-form__program ev-form__input"
                       disabled={true}
                       label="Program"
                       value={application.programName}
                       InputLabelProps={{shrink: true}}
            />
          </Tooltip>
        </div>

        <div className="ev-form__row ev-form__program-dates">
          <KeyboardDatePicker
            className="ev-form__start-date ev-form__input"
            label="Start Date"
            autoOk
            required
            format="MM/dd/yyyy"
            InputAdornmentProps={{position: 'end'}}
            InputLabelProps={{shrink: true}}
            InputProps={{'data-testid': 'start-date'}}
            variant="inline"
            value={formInputs.startDate}
            onChange={
              (date) => handleFormInputs(produce(formInputs, draftFormInputs => {
                fillStartDate(draftFormInputs, date);
              }))
            }
          />

          <KeyboardDatePicker
            className="ev-form__grad-date ev-form__input"
            label="Grad Date"
            autoOk
            required
            format="MM/dd/yyyy"
            InputAdornmentProps={{position: 'end'}}
            InputLabelProps={{shrink: true}}
            InputProps={{'data-testid': 'grad-date'}}
            variant="inline"
            value={formInputs.gradDate}
            disabled={!!application.isProgramCpl}
            onChange={
              (date) => handleFormInputs({
                ...formInputs,
                gradDate: date
              })
            }
          />
        </div>

        <div className="ev-form__row">
          <FormControl className="ev-form__financial-aid ev-form__input">
            <MyInputLabel>Financial Aid
              <IconTooltip
                data-testid="financial-aid-tooltip-icon"
                placement="bottom-start"
                title="This field should only be used for individuals who have received a type of financial aid. If this does not apply to your student, please leave the field blank. Financial aid + Confirmed Loan Amount cannot be greater than the Cost of Attendance for this program."
              />
            </MyInputLabel>
            <Input
              type="number"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              inputProps={{'data-testid': 'financial-aid'}}
              onChange={
                (e) => handleFormInputs({
                  ...formInputs,
                  financialAid: e.target.value,
                })
              }
            />
          </FormControl>

          <FormControl className="ev-form__enrollment-status ev-form__input">
            <InputLabel required shrink >Enrollment Status</InputLabel>
            <NativeSelect
              inputProps={{'data-testid': 'enrollment-status'}}
              onChange={
                (e) => handleFormInputs({
                  ...formInputs,
                  enrollmentStatus: e.target.value,
                })
              }
            >
              <option key='default' value=''></option>
              <option key='1' value='half-time'>At least half time</option>
              <option key='2' value='full-time'>Full time</option>
            </NativeSelect>
          </FormControl>
        </div>

        <h4 className="ev-form__section-header">Schedule</h4>
        <div className="ev-form__number-of-disbursements-button-group-container">
          <p className="ev-form__section-title">Number of Disbursements</p>

          <ToggleButtonGroup
            className="ev-form__disbursement-button-group"
            exclusive
            value={numDisbursements}
            aria-label="outlined primary button group"
            data-testid="disbursement-button-group"
          >
            <ToggleButton className={numDisbursements === 1 ? 'ev-form__disbursement-button-group--selected' : ''}
                          onClick={setNumDisbursements} value="1">1</ToggleButton>
            <ToggleButton className={numDisbursements === 2 ? 'ev-form__disbursement-button-group--selected' : ''}
                          onClick={setNumDisbursements} value="2">2</ToggleButton>
            <ToggleButton className={numDisbursements === 3 ? 'ev-form__disbursement-button-group--selected' : ''}
                          onClick={setNumDisbursements} value="3">3</ToggleButton>
            {Environment.schoolFourDisbursementsEnabled(application.schoolId) &&
              <ToggleButton className={numDisbursements === 4 ? 'ev-form__disbursement-button-group--selected' : ''}
                            onClick={setNumDisbursements} value="4">4</ToggleButton>
            }
          </ToggleButtonGroup>
        </div>

        <div className="ev-form__row">
          <FormControl className="ev-form__requested-loan-amount ev-form__input">
            <InputLabel shrink
                        disabled={true}
                        htmlFor="ev-form-requested-loan-amount"
            >
              Requested Loan Amount
            </InputLabel>
            <Input
              id="ev-form-requested-loan-amount"
              disabled={true}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              value={application.loanBreakdown.requestedLoanAmount.toLocaleString()}
            />
          </FormControl>

        </div>

        <div className="ev-form__row">
          <FormControl className="ev-form__loan-amount ev-form__input">
            <InputLabel required shrink>Confirmed Loan Amount</InputLabel>
            <Input
              type="number"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              inputProps={{'data-testid': 'confirmed-loan-amount'}}
              onChange={
                (e) => handleFormInputs(produce(formInputs, draftFormInputs => {
                  draftFormInputs.confirmedLoanAmount = e.target.value;
                  populateDisbursements(numDisbursements, draftFormInputs)
                }))
              }
            />
          </FormControl>

          {hasDisbursementToStudent ?
            <FormControl className="ev-form__disbursement-to-student ev-form__input">
              <MyInputLabel required shrink htmlFor="disbursement-to-student">
                Disbursement To Student
                <IconTooltip
                  iconProps={{'data-testid': 'disbursement-to-student-tooltip-icon'}}
                  placement="top-end"
                  title="This field should only be used if money will be disbursed directly to the student from Meritize. If this does not apply to your student, please enter $0 in this field."
                />
              </MyInputLabel>
              <Input
                type="number"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                inputProps={{'data-testid': 'disbursement-to-student', 'id': 'disbursement-to-student'}}
                onChange={
                  (e) => handleFormInputs({
                    ...formInputs,
                    disbursementToStudent: e.target.value
                  })
                }
              />
            </FormControl> : ""}
        </div>

        {[...Array(numDisbursements).keys()].map((i) => {
          const dNum = i + 1;
          return <div className="ev-form__row" key={dNum}>
            <FormControl className="ev-form__disbursement-amount ev-form__input">
              <InputLabel required shrink htmlFor={`ev-form-disbursement-${dNum}-amount`}>{dNum}. Amount</InputLabel>
              <Input
                type="number"
                id={`ev-form-disbursement-${dNum}-amount`}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                inputProps={{'data-testid': 'disbursement-amount'}}
                value={formInputs.disbursements[i].amount || ''}
                onChange={
                  (e) => handleFormInputs(produce(formInputs, draftFormInputs => {
                    draftFormInputs.disbursements[i].amount = e.target.value;
                  }))
                }
              />
            </FormControl>
            <KeyboardDatePicker
              className="ev-form__disbursement-date ev-form__input"
              minDate={minDates[i]}
              minDateMessage={minDateMessages[i]}
              label="Date"
              autoOk
              disablePast
              required
              format="MM/dd/yyyy"
              InputProps={{'data-testid': 'disbursement-date'}}
              InputLabelProps={{shrink: true}}
              InputAdornmentProps={{position: 'end'}}
              variant="inline"
              shouldDisableDate={
                (date) => {
                  return disableAllExceptFridaysAfterThisWeek(date, (application.delayedDisbursementDays !== 0) ? formInputs.startDate : new Date(), application.delayedDisbursementDays)
                }
              }
              value={formInputs.disbursements[i].date}
              onChange={
                (date) => handleFormInputs(produce(formInputs, draftFormInputs => {
                  fillDisbursementDates(draftFormInputs, i, date);
                }))
              }
            />
          </div>;
        })}

        {(errorMessages) &&
          <div className="ev-form__row" data-testid="error-msg-container">
            <Box
              border={1}
              borderColor="error.main"
              borderRadius="borderRadius"
              variant="outlined"
              className="ev-form__error-message-box"
            >
              {errorMessages.map((obj, index) => {
                return <div data-testid="error-msg" key={index}>{obj}</div>
              })}
            </Box>
          </div>
        }
        <div className="ev-form__disclaimer-container">
          <p className="ev-form__section-title">If you have questions about the available disbursement dates, please
            contact your Partnerships Manager.</p>
        </div>
      </div>
      <div className="ev-form__action-buttons">
        <div className="ev-form__submit-button-container">
          <CTAButton btnStyle="confirm" onClick={validateAndSubmitForm} classAffix="ev-drawer__submit-button"
                     {...(submitEnabled ? {} : {disabled: true})}
                     data-testid="submit-btn">Submit</CTAButton>
        </div>

        <Button disableRipple={true} className="ev-form__cancel-button" onClick={onCancel}><u>CANCEL</u></Button>
      </div>
      <Dialog
        open={openConfirmDialog}
      >
        <DialogTitle id="alert-dialog-title" className='ev-form__confirm-dialog__title_container'>

        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className='ev-form__confirm-dialog__content'>
            <span className='ev-form__confirm-dialog__inner-text'>
              It looks like you have one or more disbursements that are currently scheduled for after the graduation date.
            </span>
            <span className='ev-form__confirm-dialog__inner-text'>
              Is this correct?
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions className='ev-form__confirm-dialog__button_container'>
          <Button data-testid="confirm_dialog-no-btn" onClick={handleCloseConfirmDialog}
                  className='ev-form__confirm-dialog__cancel_button'>No</Button>
          <CTAButton data-testid="confirm_dialog-yes-btn" onClick={handleOkConfirmDialog}
                     className='btn-success ev-form__confirm-dialog__ok_button' autoFocus>
            Yes
          </CTAButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}
