import {useEffect, useState} from 'react'

export default function useEffectIfMounted(effect, dependencies = null) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (mounted)
      effect();
  }, dependencies === null ? null : [...dependencies, mounted])
}
