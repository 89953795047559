import React from 'react';
import Collapse from "@material-ui/core/Collapse";
import ApplicantDetails from "../ApplicantDetails/ApplicantDetails";
import LoanInfo from "../LoanInfo/LoanInfo";
import Disbursements from "../Disbursements/Disbursements";
import LoanBreakdown from "../LoanBreakdown/LoanBreakdown";
import './css/ApplicationExpanded.scss';

const hasAtLeastOnePaidDisbursement = (application) => {
  return application.disbursements.length >= 1 &&
    application.disbursements.find(d => d.disbursed);
}

const isRefundable = hasAtLeastOnePaidDisbursement;

function ApplicationExpandable(props) {
  let {application, expanded, toggleExpanded, changeExpanderHover, openRefundDrawer, hasDisbursementToStudent} = props;

  return (
    <React.Fragment>
      <Collapse in={expanded} collapsedSize={0}>
        <hr className='application-expandable__line-break'/>
        <div className='application-expandable__container'>
          <div className='applicant-details'>
            <ApplicantDetails application={application}/>
            {!!openRefundDrawer && isRefundable(application) &&
              <button className="applicant-details__refund cta-button cta-button--action" onClick={() => openRefundDrawer(application)}
                      data-testid="refund">Refund/Withdraw Student</button>}
          </div>

          <LoanInfo application={application}/>
          <LoanBreakdown isEvNeeded={application.isEvNeeded}
                         loanBreakdownData={application.enrollmentVerified ? application.loanBreakdown : null}
                         hasDisbursementToStudent={hasDisbursementToStudent}
          />
          <Disbursements disbursements={application.disbursements}
                         totalDisbursementAmount={application.totalDisbursementAmount}/>
        </div>
      </Collapse>

      <div
        data-testid="application-expandable-arrow"
        onMouseEnter={() => changeExpanderHover(true)}
        onMouseLeave={() => changeExpanderHover(false)}
        className={`application-expandable__arrow ${expanded ? 'application-expandable__arrow--up' : 'application-expandable__arrow--down'}`}
        onClick={() => toggleExpanded(application)}
      />
    </React.Fragment>
  );
}

export default ApplicationExpandable;
