import React, {useCallback, useEffect, useRef} from 'react';
import './css/StatusFilterBox.scss';
import CTAButton from '../CTAButton/CTAButton';
import Button from '@material-ui/core/Button';
import useStateWithPromise from '../../util/useStateWithPromise';

const StatusFilterBox = (props) => {

  let {toggleShowStatusFilter, onApply, checkedStatuses, setCheckedStatuses} = props;
  const [apply, setApply] = useStateWithPromise(false);

  const [notAppliedCheckedStatuses, setNotAppliedCheckedStatuses] = useStateWithPromise(checkedStatuses)

  const ref = useRef(null)

  useEffect(() => {
    setApply(false)
    onApply()
  }, [apply])

  const handleReset = async () => {
    await setCheckedStatuses([])
    setApply(true)
    toggleShowStatusFilter()
  }

  const handleApply = async () => {
    await setCheckedStatuses(notAppliedCheckedStatuses)
    await setApply(true)
    toggleShowStatusFilter()
  }

  const clickListener = useCallback(
    (e) => {
      if (!(ref.current).contains(e.target)) {
        toggleShowStatusFilter()
      }
    },
    [ref.current],
  )

  const handleCheckBoxChange = (event) => {
    let label = event.target.parentElement.textContent.trim();
    label = (label === 'Co-Applicant Required') ? 'Co-Borrower Required' : label
    let checked = [...notAppliedCheckedStatuses];
    if (event.target.checked) {
      checked.push(label)
      setNotAppliedCheckedStatuses(checked);
    } else {
      var index = checked.indexOf(label)
      if (index !== -1) {
        checked.splice(index, 1);
        setNotAppliedCheckedStatuses(checked);
      }
    }
  }


  useEffect(() => {
    // Attach the listeners on component mount.
    document.addEventListener('click', clickListener);
    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener('click', clickListener);
    }
  }, [toggleShowStatusFilter])

  return (
    <div ref={ref} className="status-filter-box" data-testid="status-filter-box__container">
      <span className="status-filter-box__content">
        <div className="status-filter-box__header">
          <div className="status-filter-box__title">Status</div>
          <div data-testid="status-filter-box-close-icon" className="status-filter-box__close-icon"
               onClick={toggleShowStatusFilter}>X</div>
        </div>

        <div className="status-filter-box__item"><label><input type="checkbox"
                                                               checked={notAppliedCheckedStatuses.includes('Application Received')}
                                                               name="status-filter" onChange={handleCheckBoxChange}/>Application Received</label></div>
        <div className="status-filter-box__item"><label><input type="checkbox"
                                                               checked={notAppliedCheckedStatuses.includes('Application Completed')}
                                                               name="status-filter" onChange={handleCheckBoxChange}/>Application Completed</label></div>
        <div className="status-filter-box__item"><label><input type="checkbox"
                                                               checked={notAppliedCheckedStatuses.includes('Offer Sent')}
                                                               name="status-filter" onChange={handleCheckBoxChange}/>Offer Sent</label></div>
        <div className="status-filter-box__item"><label><input type="checkbox"
                                                               checked={notAppliedCheckedStatuses.includes('Offer Accepted')}
                                                               name="status-filter" onChange={handleCheckBoxChange}/>Offer Accepted</label></div>
        <div className="status-filter-box__item"><label><input type="checkbox"
                                                               checked={notAppliedCheckedStatuses.includes('Contract Sent')}
                                                               name="status-filter" onChange={handleCheckBoxChange}/>Contract Sent</label></div>
        <div className="status-filter-box__item"><label><input type="checkbox"
                                                               checked={notAppliedCheckedStatuses.includes('Funding Secured')}
                                                               name="status-filter" onChange={handleCheckBoxChange}/>Funding Secured</label></div>
        <div className="status-filter-box__item"><label><input type="checkbox"
                                                               checked={notAppliedCheckedStatuses.includes('Declined')}
                                                               name="status-filter" onChange={handleCheckBoxChange}/>Declined</label></div>
        <div className="status-filter-box__item"><label><input type="checkbox"
                                                               checked={notAppliedCheckedStatuses.includes('Co-Borrower Required')}
                                                               name="status-filter" onChange={handleCheckBoxChange}/>Co-Applicant Required</label></div>
        <div className="status-filter-box__action-buttons-div">
          <Button className="status-filter-box__reset-button"
                  onClick={handleReset}
          >Reset</Button>
          <CTAButton
            data-testid="status-filter-apply-box-button"
            onClick={handleApply}
          >Apply</CTAButton>
        </div>

      </span>
    </div>
  );
}

export default StatusFilterBox;
