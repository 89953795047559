import React from 'react';
import "component/ApplicationStatus/StatusBar/css/StatusBar.scss";

const SCHOOL = 6;
const GRADUATED = 7;
const bubbleClassMap = {
  "o": "status-bar__step--completed",
  "O": "status-bar__step--last-completed",
  "X": "status-bar__step--failed",
  "S": "status-bar__school-step--completed",
  "R": "status-bar__school-step--refund",
  "r": "status-bar__school-step--refund-completed",
  "G": "status-bar__graduation-step--completed",
  "g": "status-bar__graduation-step--refund",
  "-": "",
};

function getDisplayInfoForStatus(status, refundEventType, refundStatus) {
  switch (status) {
    case 'application_received':  return ["status-bar__line--step1",  'O-------', 0];
    case 'application_completed': return ["status-bar__line--step2",  'oO------', 1];
    case 'declined':              return ["status-bar__line--step2x", 'oX------', 1];
    case 'coapplicant_required':  return ["status-bar__line--step2x", 'oX------', 1];
    case 'application_withdrawn': return ["status-bar__line--step2x", 'oX------', 1];
    case 'offer_sent':            return ["status-bar__line--step3",  'ooO-----', 2];
    case 'offer_accepted':        return ["status-bar__line--step4",  'oooO----', 3];
    case 'contract_sent':         return ["status-bar__line--step5",  'ooooO---', 4];
    case 'funding_secured':       return ["status-bar__line--step6",  'oooooO--', 5];
    case 'in_school':
      if ((refundEventType === 'refund' && refundStatus !== 'done') || refundEventType ===  'refund_and_withdrawal') {
        return ["status-bar__line--step6", 'oooooOR-', SCHOOL];
      } else if (refundEventType === 'refund' && refundStatus === 'done') {
        return ["status-bar__line--step6", 'oooooOr-', SCHOOL];
      } else if (refundEventType === 'withdrawal') {
        return ["status-bar__line--step6", 'oooooOR-', SCHOOL];
      } else {
        return ["status-bar__line--step6", 'oooooOS-', SCHOOL];
      }
    case 'graduated':
      if(refundEventType === 'refund' &&
        (refundStatus === 'awaiting_school_confirmation' || refundStatus === 'awaiting_school_funds' ||
        refundStatus === 'awaiting_meritize_risk_share')){
        return ["status-bar__line--step6",'oooooOSg', GRADUATED];
      } else{
        return ["status-bar__line--step6", 'oooooOSG', GRADUATED];
      }
    case 'expired':               return ["status-bar__line--step2x", 'oX------', 1];
    default :                     return null;
  }
}

export default function StatusBar(props) {
  let [barLineClass, matrix, labelIndex] = (getDisplayInfoForStatus(props.status, props.refundEventType, props.refundStatus) || []);

  if (barLineClass === undefined) {
    return <></>;
  }

   const getStatusDiv = (index, baselineCssClasses) => {
    return <div key={index} className={`${baselineCssClasses} ${bubbleClassMap[matrix.charAt(index)]}`}>
      {index === labelIndex &&
      <div className="status-bar__label" data-testid="status-bar-label">{props.labelText}</div>}
    </div>;
  }

  return <div className="status-bar">
    <div className={`status-bar__line ${barLineClass}`} data-testid="barline"/>
    <div>
      {
        [0, 1, 2, 3, 4, 5].map(i => getStatusDiv(i, 'status-bar__step'))
      }
    </div>
    {getStatusDiv(SCHOOL,'status-bar__school-step')}
    {getStatusDiv(GRADUATED,'status-bar__graduation-step')}
  </div>
}
